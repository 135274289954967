import { ReactComponent as CheckCircleIcon } from '@material-design-icons/svg/round/check_circle.svg';
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg';
import { ReactComponent as InfoCircleIcon } from '@material-design-icons/svg/round/info.svg';
import { ReactComponent as Warning } from '@material-design-icons/svg/round/warning.svg';
import { motion } from 'framer-motion';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import UnstyledButton from '../UnstyledButton';

const sharedIconStyles = `
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
`;

interface AlertProps {
  $inline: boolean;
  $type: 'error' | 'info' | 'success' | 'warning';
}

export const Alert = styled(motion.div)<AlertProps>`
  padding: 0.75rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  ${({ $inline }) => {
    if ($inline) {
      return `
        display: inline-flex;
      `;
    } else {
      return `
        display: flex;
    `;
    }
  }}

  ${({ $type }) => {
    if ($type === 'error') {
      return `
        background-color: var(--error-50);
        color: var(--error-600);

        a {
          color: var(--error-600);
        }
      `;
    } else if ($type === 'success') {
      return `
        background-color: var(--success-50);
        color: var(--success-800);

        a {
          color: var(--success-800);
        }
      `;
    } else if ($type === 'warning') {
      return `
        background-color: var(--warning-50);
        color: var(--warning-600);

        a {
          color: var(--warning-600);
        }
      `;
    } else {
      return `
        background-color: var(--blue-50);
        color: var(--blue-600);

        a {
          color: var(--blue-600);
        }
      `;
    }
  }}
`;

export const Close = styled(CloseIcon)`
  ${sharedIconStyles}
`;

export const CloseButton = styled(UnstyledButton)`
  align-self: flex-start;
  width: 1.5rem;
  height: 1.5rem;
`;

export const ErrorIcon = styled(InfoCircleIcon)`
  ${sharedIconStyles}
`;

export const Header = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  font-weight: 500;
`;

export const InfoIcon = styled(InfoCircleIcon)`
  ${sharedIconStyles}
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  ${sharedIconStyles}
`;

export const Text = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-grow: 1;
`;

export const WarningIcon = styled(Warning)`
  ${sharedIconStyles}
`;
