import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useIsFirstRender } from 'usehooks-ts';

import { generateNetworkProvidersLocations, NetworkProvidersLocation } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

export interface NetworkLocationsFilters {
  'benchmark_communities[]'?: string[];
  order_by?: {
    column: 'provider_count' | 'state';
    dir: 'asc' | 'desc';
  };
  'organizations[]'?: string[];
  page?: number;
  per_page?: number;
  'specialties[]'?: string[];
  'states[]'?: string[];
}

interface UseGetNetworkLocations {
  (filters: NetworkLocationsFilters): {
    isFetching: boolean;
    networkLocations: NetworkProvidersLocation[] | undefined;
    networkLocationsTotalPages: number;
    updateNetworkLocationFilters: (newFilters: Partial<NetworkLocationsFilters>) => void;
  };
}

const useGetNetworkLocations: UseGetNetworkLocations = filters => {
  const { getRequest, reportError } = useApiRequest();
  const isFirstRender = useIsFirstRender();

  const [networkLocations, setNetworkLocations] = useState<NetworkProvidersLocation[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState(filters);
  const [networkLocationsTotalPages, setNetworkLocationsTotalPages] = useState(1);

  const fetchNetworkLocations = useCallback(
    async (filters: NetworkLocationsFilters) => {
      setIsFetching(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_BASE_PATH
      }/networks/providers/locations${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APINetworksProvidersLocations['data'];
          meta?: APINetworksProvidersLocations['meta'];
        };
        setNetworkLocations(
          data !== undefined ? data.map(fields => generateNetworkProvidersLocations(fields)) : []
        );
        setNetworkLocationsTotalPages(meta !== undefined ? meta.total_pages : 1);
      } catch (error) {
        reportError(error);
      }

      setIsFetching(false);
    },
    [getRequest, reportError]
  );

  useEffect(() => {
    if (!isFirstRender) return;

    (async () => {
      await fetchNetworkLocations(filters);
    })();
  }, [fetchNetworkLocations, isFirstRender, filters]);

  const updateNetworkLocationFilters = (newFilters: Partial<NetworkLocationsFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkLocationsFilters] === undefined
        ? delete newFilters[key as keyof NetworkLocationsFilters]
        : {}
    );
    if (isEqual(newFilters, previousFilters)) return;

    setPreviousFilters({ ...newFilters });
    fetchNetworkLocations(newFilters);
  };

  return {
    isFetching,
    networkLocations,
    networkLocationsTotalPages,
    updateNetworkLocationFilters
  };
};

export default useGetNetworkLocations;
