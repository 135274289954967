import { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  direction?: 'horizontal' | 'vertical';
  layout?: 'fifths' | 'fifty-fifty' | 'quarters' | 'single' | 'thirds' | 'two-thirds';
}

const DataReportRow = ({
  children,
  direction = 'horizontal',
  layout = 'single'
}: PropsWithChildren<Props>) => (
  <S.DataReportRow
    $direction={direction}
    $layout={layout}
    className="data-row"
  >
    {children}
  </S.DataReportRow>
);

export default DataReportRow;
