import Pagination from 'src/components/Pagination';
import styled from 'styled-components';

export const CenterColumn = styled.div`
  text-align: center;
  width: 100%;
`;

export const TablePagination = styled(Pagination)`
  margin: 1.5rem auto 0;
`;

export const TextOverflowWrapper = styled.div`
  max-width: 12.5rem; /* 200px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
