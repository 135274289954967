import DataEmptyState from '../DataEmptyState';

import * as S from './styles';

interface Props {
  'data-cy'?: string;
  detailText: string;
  isEmptyState?: boolean;
  numberHighlight: number | string;
  percent?: number;
  variant: 'large' | 'number-and-percent' | 'small';
}

const DataHighlight = ({
  detailText,
  isEmptyState = false,
  numberHighlight,
  percent,
  variant = 'small',
  ...args
}: Props) =>
  variant === 'number-and-percent' && percent !== undefined ? (
    <S.SingleDataCard {...args}>
      <S.NumberHighlight>
        {isEmptyState ? (
          <DataEmptyState />
        ) : (
          <>
            {`${percent.toFixed(0)}%`}
            <S.ParenthesesText>{` (${numberHighlight}) `}</S.ParenthesesText>
          </>
        )}
      </S.NumberHighlight>

      <S.DataHighlightDescriptionText>{detailText}</S.DataHighlightDescriptionText>
    </S.SingleDataCard>
  ) : variant === 'large' ? (
    <S.SingleReportDataCard>
      <S.LargeNumberHighlight>
        {isEmptyState ? <DataEmptyState /> : numberHighlight}
      </S.LargeNumberHighlight>
      <S.LargeDataHighlightDescriptionText>{detailText}</S.LargeDataHighlightDescriptionText>
    </S.SingleReportDataCard>
  ) : (
    <S.SingleDataCard {...args}>
      <S.NumberHighlight>{isEmptyState ? <DataEmptyState /> : numberHighlight}</S.NumberHighlight>
      <S.DataHighlightDescriptionText>{detailText}</S.DataHighlightDescriptionText>
    </S.SingleDataCard>
  );

export default DataHighlight;
