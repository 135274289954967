import { useAuth0 } from '@auth0/auth0-react';
import { OverlayContainer } from 'react-aria';

import ModalDialog from '../ModalDialog';

import * as S from './styles';

const SignedOutModalDialog = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignInButtonPress = () => {
    loginWithRedirect();
  };

  return (
    <OverlayContainer>
      <ModalDialog
        data-cy="signed-out-modal-dialog"
        isOpen
        title="Your session has expired"
      >
        <S.Text>Please sign in again.</S.Text>
        <S.Button
          onPress={handleSignInButtonPress}
          size="large"
        >
          Sign in
        </S.Button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export default SignedOutModalDialog;
