import CommunityGraphs from 'src/components/Reporting/CommunityGraphs';
import DataCard from 'src/components/Reporting/DataCard';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import DataContainer from 'src/components/Reporting/DataContainer';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import { NetworkOverview } from 'src/pages/Dashboard/utils';

interface Props {
  networkOverview?: NetworkOverview;
}

export const NetworkOverviewSection = ({ networkOverview }: Props) => {
  const numFormatter = Intl.NumberFormat('en', { notation: 'compact' });

  return (
    <DataContainer
      data-cy="network-overview"
      hideBorder
    >
      <DataReportRow layout="thirds">
        <DataHighlight
          data-cy="total-network-providers"
          detailText="Providers"
          numberHighlight={numFormatter.format(networkOverview?.totalNetworkProviders ?? 0)}
          variant="small"
        />
        <DataHighlight
          data-cy="total-inclusive-providers"
          detailText="Inclusive providers"
          numberHighlight={numFormatter.format(
            networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
          )}
          variant="small"
        />
        <DataHighlight
          data-cy="total-active-network-providers"
          detailText="On Violet"
          numberHighlight={numFormatter.format(networkOverview?.totalActiveNetworkProviders ?? 0)}
          variant="small"
        />
      </DataReportRow>
      <DataReportRow layout="single">
        <DataCard>
          <DataCardHeader
            badgeColor="gray"
            badgeText="All providers"
            description="This chart represents providers within the network who have had their cultural competence assessed and benchmarked by Violet. This encompasses both active providers who have directly onboarded onto the platform and non-active providers whose benchmarks are inferred through data analytics."
            title="Cultural competencies"
            titleVariant="h3"
          />
          <CommunityGraphs
            data={networkOverview?.networkInclusivityPercentages}
            dataType="benchmarks"
          />
        </DataCard>
      </DataReportRow>
    </DataContainer>
  );
};
