import { ReactComponent as OrganizationIcon } from '@material-design-icons/svg/round/apartment.svg';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useRef } from 'react';
import { useNumberFormatter } from 'react-aria';
import { Cell, Column, Item, Row, TableBody, TableHeader } from 'react-stately';
import Badge from 'src/components/Badge';
import ViewDetailsButton from 'src/components/Buttons/ViewDetailsButton';
import Label from 'src/components/Label';
import PageTitle from 'src/components/PageTitle';
import CommunityGraphs from 'src/components/Reporting/CommunityGraphs';
import DataCard from 'src/components/Reporting/DataCard';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Spinner from 'src/components/Spinner';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import useAnalytics from 'src/hooks/useAnalytics';
import useUser from 'src/hooks/useUser';
import { STATES } from 'src/pages/constants';
import { NETWORK_INCLUSIVITY_BASE_URL } from 'src/pages/Dashboard/MyNetwork/Wrapper';
import { downloadPdf } from 'src/utils/downloadPdf';
import { downloadPng } from 'src/utils/downloadPng';

import useGetNetworkLocations from '../hooks/useGetNetworkLocations';
import useGetNetworkOrgMembers from '../hooks/useGetNetworkOrgMembers';
import useGetNetworkOverview from '../hooks/useGetNetworkOverview';

import * as S from './styles';

const MyNetwork = () => {
  // SEARCH PARAMS: If you need to implement search params,
  // please use "useSearchParams"
  const { analytics } = useAnalytics();
  const networkOverviewReportEnabled = useFeatureFlagEnabled('network_overview_report_export');

  const { user } = useUser();
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'network_manager'
  )?.organization.name;

  const { isFetching: isFetchingOverview, networkOverview } = useGetNetworkOverview();
  const { isFetching: isFetchingLocation, networkLocations } = useGetNetworkLocations({
    order_by: {
      column: 'provider_count',
      dir: 'desc'
    },
    page: 1,
    per_page: 5
  });
  const { isFetching: isFetchingNetworkOrgMembers, networkOrgMembers } = useGetNetworkOrgMembers({
    order_by: {
      column: 'total_providers',
      dir: 'desc'
    },
    page: 1,
    per_page: 5
  });
  const compactNumFormatter = useNumberFormatter({ notation: 'compact' });
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  const generateReportTitle = () => {
    if (networkOverview === undefined) {
      return '';
    }
    const date = new Date();
    const formattedDate = date
      .toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
      .replace(/\//g, '-');
    return `Violet_NetworkOverview_${organizationName?.replaceAll(' ', '')}_${formattedDate}`;
  };

  const networkOverviewReportRef = useRef<HTMLDivElement>(null);

  const handleDownloadButtonClick = async (downloadType: number | string): Promise<void> => {
    if (downloadType === 'png') {
      await downloadPng(networkOverviewReportRef.current as HTMLElement, generateReportTitle());
    } else if (downloadType === 'pdf') {
      await downloadPdf(networkOverviewReportRef.current as HTMLElement, generateReportTitle());
    }

    const { organization } = user.organization_memberships.find(
      membership => membership.member_role === 'network_manager'
    )!;

    analytics?.track('Report Downloaded', {
      organization_id: organization.id,
      organization_name: organization.name,
      report_format: downloadType,
      report_name: 'Network overview'
    });
  };

  return (
    <>
      <S.HiddenOffscreenReportRender ref={networkOverviewReportRef}>
        {networkOverview && <S.OverviewReport networkOverview={networkOverview} />}
      </S.HiddenOffscreenReportRender>
      <PageTitle
        actionButton={
          networkOverviewReportEnabled === true && (
            <S.ExportButtonWithMenu
              data-cy="export-network-overview-button"
              label="Export"
              matchButtonWidth
              onAction={handleDownloadButtonClick}
            >
              <Item key="pdf">PDF</Item>
              <Item key="png">PNG</Item>
            </S.ExportButtonWithMenu>
          )
        }
        data-cy="network-overview-title-container"
        description="Inclusivity and diversity insights are based on the overall cultural competencies and demographics of providers within the network. Violet generates these insights using a proprietary benchmarking framework, in conjunction with providers' self-reported identity disclosures."
        labelComponent={
          <Label
            data-cy="network-label"
            icon={OrganizationIcon}
          >
            {organizationName ?? ''}
          </Label>
        }
        title="Overview"
        titleVariant="h1"
      />
      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <S.DataWrapper data-cy="network-overview">
          <DataReportRow layout="quarters">
            <DataHighlight
              data-cy="total-network-providers"
              detailText="Providers"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalNetworkProviders ?? 0
              )}
              variant="small"
            />
            <DataHighlight
              data-cy="total-network-organizations"
              detailText="Provider organizations"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalNetworkOrganizations ?? 0
              )}
              variant="small"
            />
            <DataHighlight
              data-cy="total-network-providers-benchmarks"
              detailText="Inclusive providers"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
              )}
              variant="small"
            />
            <DataHighlight
              data-cy="total-active-network-providers"
              detailText="On Violet"
              numberHighlight={compactNumFormatter.format(
                networkOverview?.totalActiveNetworkProviders ?? 0
              )}
              variant="small"
            />
          </DataReportRow>
          <DataReportRow layout="fifty-fifty">
            <DataCard data-cy="benchmarks-community-graphs">
              <DataCardHeader
                badgeColor="gray"
                badgeText="All providers"
                description="This chart represents network providers who have had their cultural competence assessed and benchmarked by Violet. It includes both providers that are active on the platform and providers whose Benchmarks are inferred through data analytics."
                title="Cultural competencies"
                titleVariant="h3"
              />
              <CommunityGraphs
                data={networkOverview?.networkInclusivityPercentages}
                dataType="benchmarks"
                linkToPage={NETWORK_INCLUSIVITY_BASE_URL}
              />
            </DataCard>
            <DataCard data-cy="demographics-community-graphs">
              <DataCardHeader
                badgeColor="green"
                badgeText="On Violet"
                description="This chart represents active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
                title="Diversity"
                titleVariant="h3"
              />
              <CommunityGraphs
                data={networkOverview?.networkDiversityPercentages}
                dataType="demographics"
                linkToPage="/dashboard/my-network/demographics"
              />
            </DataCard>
          </DataReportRow>
        </S.DataWrapper>
      )}

      <S.TableWrapper>
        <S.SingleTable>
          <DataCardHeader
            description="This table displays the density of inclusive providers by geographic service area. Click on a service area to view more details."
            title="Service areas"
          />
          {isFetchingLocation ? (
            <Spinner withWrapper />
          ) : networkLocations && networkLocations.length > 0 ? (
            <>
              <S.OverviewLocationTable
                aria-label="Table of service area locations"
                data-cy="network-service-area-table"
                hasLinkedRows
              >
                <TableHeader>
                  <Column key="state">Service area</Column>
                  <Column key="provider_count">
                    <TableNumberCell alignRight>Provider count</TableNumberCell>
                  </Column>
                  <Column key="bipoc_inclusivity">
                    <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
                  </Column>
                  <Column key="lgbq_inclusivity">
                    <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
                  </Column>
                  <Column key="tgnc_inclusivity">
                    <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
                  </Column>
                </TableHeader>
                <TableBody>
                  {networkLocations.map(location => (
                    <Row
                      key={location.state}
                      data-cy="network-service-area-row"
                    >
                      <Cell>
                        <TableLinkedCell
                          data-cy="service-area-state-filter-link"
                          to={`${NETWORK_INCLUSIVITY_BASE_URL}&states%5B%5D=${location.state}`}
                        >
                          {STATES.find(state => state.abbreviation === location.state)?.name}
                        </TableLinkedCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="service-area-total-providers">
                          {commaNumFormatter.format(location.totalProviders)}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="bipoc-inclusivity-percentage">
                          {`${location.inclusivity.bipoc.percentage}%`}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="lgbq-inclusivity-percentage">
                          {`${location.inclusivity.lgbq.percentage}%`}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell data-cy="tgnc-inclusivity-percentage">
                          {`${location.inclusivity.tgnc.percentage}%`}
                        </TableNumberCell>
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </S.OverviewLocationTable>
              <ViewDetailsButton
                data-cy="service-area-view-details-button"
                linkToPage={NETWORK_INCLUSIVITY_BASE_URL}
                overrideText="View all service areas"
              />
            </>
          ) : (
            <S.EmptyState>No service areas found.</S.EmptyState>
          )}
        </S.SingleTable>
        <S.SingleTable>
          <DataCardHeader
            description="This table displays the largest provider organizations within your network. Click on an organization for information about their inclusivity breakdown."
            title="Provider organizations"
          />
          {isFetchingNetworkOrgMembers ? (
            <Spinner withWrapper />
          ) : networkOrgMembers && networkOrgMembers.length > 0 ? (
            <>
              <S.ProviderOrganizationsTable
                aria-label="Provider organizations data"
                hasLinkedRows
              >
                <TableHeader>
                  <Column>Name</Column>
                  <Column>
                    <TableNumberCell alignRight>Provider count</TableNumberCell>
                  </Column>
                  <Column>
                    <S.CenterColumn>On Violet</S.CenterColumn>
                  </Column>
                </TableHeader>
                <TableBody>
                  {networkOrgMembers.map(member => (
                    <Row
                      key={member.id}
                      data-cy="provider-organization-row"
                    >
                      <Cell>
                        <TableLinkedCell
                          data-cy="provider-organization-link"
                          to={`${NETWORK_INCLUSIVITY_BASE_URL}&organizations%5B%5D=${member.id}`}
                        >
                          {member.name}
                        </TableLinkedCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell>
                          {commaNumFormatter.format(member.totalProviders)}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <S.CenterDataCell>
                          <Badge
                            color={member.onViolet ? 'green' : 'red'}
                            size="small"
                          >
                            {member.onViolet ? 'Yes' : 'No'}
                          </Badge>
                        </S.CenterDataCell>
                      </Cell>
                    </Row>
                  ))}
                </TableBody>
              </S.ProviderOrganizationsTable>
              <ViewDetailsButton
                data-cy="providers-organizations-view-details-button"
                linkToPage={NETWORK_INCLUSIVITY_BASE_URL}
                overrideText="View all organizations"
              />
            </>
          ) : (
            <S.EmptyState>No organizations found.</S.EmptyState>
          )}
        </S.SingleTable>
      </S.TableWrapper>
    </>
  );
};

export default MyNetwork;
