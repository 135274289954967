import { ReactComponent as greenCheckIcon } from '@material-design-icons/svg/round/check.svg';
import Select from 'src/components/FormFields/Select';
import UnstyledButton from 'src/components/UnstyledButton';
import { FOCUS_STYLES, MOBILE_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const CourseLink = styled(UnstyledButton)`
  ${TEXT_STYLES.tagLarge}
  appearance: none;
  color: var(--blue-500);
  text-decoration: none;
  border: 0;
  text-align: left;
  background-color: transparent;
  cursor: pointer;

  margin-left: 0.75rem;

  &.is-pressed {
    color: var(--blue-600);
    ${FOCUS_STYLES}
  }

  &.is-hovered {
    text-decoration: underline;
  }

  &.is-focused {
    ${FOCUS_STYLES}
  }
`;

export const DateSelect = styled(Select)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 13.68rem;
  }
`;

export const EmailReminderCheck = styled(greenCheckIcon)`
  fill: var(--success-600);
  margin-right: 0.25rem;
`;

export const EmailReminderSentText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const EngagementList = styled.ol`
  ${TEXT_STYLES.tagLarge}
  padding-left: 1rem;
  display: grid;
  gap: 0.25rem;
  margin: 0;
`;

export const EngagementTitle = styled.h3`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin-bottom: 0.75rem;
`;

export const FilterWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-column: span 2;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

// this is a hack to allow us to render the report offscreen and then download it
export const HiddenOffscreenReportRender = styled.div`
  left: -2000px;
  overflow: visible;
  position: absolute;
  top: -2000px;
`;

export const NetworkManagerButton = styled(UnstyledButton)`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  color: var(--gray-800);
  ${TEXT_STYLES.tagLarge}

  > svg {
    fill: var(--gray-400);
  }

  &:hover,
  &.is-hovered {
    border-color: var(--gray-400);

    > svg {
      fill: var(--gray-500);
    }
  }

  &:focus,
  &.is-focused {
    outline-color: var(--bright-purple-400);
  }
`;

export const NetworkManagerButtonContent = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    fill: var(--purple-400);
  }
`;

export const TeamMemberSelect = styled(Select)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: initial;
  }
`;

export const TopLearnerListItem = styled.div`
  margin-left: 0.75rem;
`;
