import { FOCUS_STYLES } from 'src/constants';
import styled from 'styled-components';

export const LinkedCell = styled.button`
  appearance: none;
  color: var(--blue-500);
  text-decoration: none;
  border: 0;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;

  &.is-pressed {
    color: var(--blue-600);
    ${FOCUS_STYLES}
  }

  &.is-hovered {
    text-decoration: underline;
  }

  &.is-focused {
    ${FOCUS_STYLES}
  }
`;
