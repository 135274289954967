import { motion } from 'framer-motion';
import { OverlayContainer } from 'react-aria';
import ModalDialogFullscreen from 'src/components/ModalDialogFullscreen';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const AnimationWrapper = styled(motion.div)`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
`;

export const AssignmentTitle = styled.h2`
  color: var(--gray-800);
  ${TEXT_STYLES.headlineSectionLevel1Regular}
  margin-bottom: 1rem;
`;

export const FiltersAndActionsWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const FullModal = styled(ModalDialogFullscreen)``;

export const FullOverlay = styled(OverlayContainer)`
  > div {
    z-index: 100;
  }
`;

export const SectionTitle = styled.h3`
  color: var(--purple-400);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  margin-bottom: 0.75rem;
`;
