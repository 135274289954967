import { Temporal } from '@js-temporal/polyfill';
import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import * as Sentry from '@sentry/react';
import { Key, RefObject, useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaComboBoxOptions,
  AriaComboBoxProps,
  AriaSearchFieldProps,
  AriaSelectProps,
  AriaSwitchProps,
  useNumberFormatter
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import {
  Cell,
  Column,
  Item,
  Row,
  SortDescriptor,
  SortDirection,
  TableBody,
  TableHeader,
  useAsyncList
} from 'react-stately';
import Button from 'src/components/Buttons/Button';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import ComboBox from 'src/components/FormFields/ComboBox';
import SearchField from 'src/components/FormFields/SearchField';
import Select from 'src/components/FormFields/Select';
import Switch from 'src/components/FormFields/Switch';
import PageTitle from 'src/components/PageTitle';
import Pagination from 'src/components/Pagination';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Spinner from 'src/components/Spinner';
import Filters from 'src/components/Table/Filters';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import useUser from 'src/hooks/useUser';
import { COMMUNITIES } from 'src/pages/constants';
import { downloadCsv } from 'src/utils/downloadCsv';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import TableEmptyState from '../../components/TableEmptyState';
import useGetNetworkEducation from '../../hooks/useGetNetworkEducation';
import useGetNetworkEducationOverview, {
  NetworkEducationOverviewFilters
} from '../../hooks/usetGetNetworkEducationOverview';
import {
  Collection,
  EducationalResource,
  generateCollection,
  generateEducationalResource
} from '../../utils';
import NetworkOrganizationFilter from '../Components/NetworkOrganizationFilter';

import * as S from './styles';

const EducationEngagement = () => {
  const formatter = useNumberFormatter();
  const [searchParams, setSearchParams] = useSearchParams();

  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const fullNameCellRef: RefObject<HTMLDivElement> = useRef(null);
  const organizationNameRef: RefObject<HTMLDivElement> = useRef(null);
  const educationNameRef: RefObject<HTMLDivElement> = useRef(null);
  const publisherNameRef: RefObject<HTMLDivElement> = useRef(null);

  const [currentPage, setCurrentPage] = useState(Number(searchParams.get('page') ?? '1'));
  const [isCourseView, setIsCourseView] = useState<boolean>(
    searchParams.get('resource_type') !== null
      ? searchParams.get('resource_type') === 'course'
      : true
  );
  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const courseList = useAsyncList({
    async load({ filterText, signal }) {
      const queryString =
        filterText !== '' ? `&${isCourseView ? 'title' : 'name'}=${filterText}` : '';
      const res = await fetch(
        `${process.env.REACT_APP_API_V2_BASE_PATH}/${
          isCourseView ? 'courses' : 'course_collections'
        }?per_page=10${queryString}`,
        { ...fetchOptions, signal }
      );
      const json = (await res.json()) as APICourseCollections | APICourses;

      const resources = isCourseView
        ? (json as APICourses).data.map(generateEducationalResource)
        : (json as APICourseCollections).data.map(fields => generateCollection(fields, user));

      return {
        items: resources
      };
    }
  });

  const [searchedProviderName, setSearchedProviderName] = useState(
    searchParams.get('user_name') ?? ''
  );
  const [searchedProviderOrganizationId, setSearchedProviderOrganizationId] = useState<Key | null>(
    searchParams.get('organizations[]') ?? null
  );
  const [selectedCommunities, setSelectedCommunities] = useState(
    searchParams.get('communities[]')?.split(',') ?? []
  );
  const [selectedDateRange, setSelectedDateRange] = useState<Key>(
    searchParams.get('date_range') ?? 'all_time'
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('order_by[column]') ?? undefined,
    direction:
      searchParams.get('order_by[dir]') === null
        ? undefined
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });

  /**************** HANDLERS ************************/
  const handleDateRangeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    const dateRange = key;

    setSelectedDateRange(dateRange);

    setSearchParams(searchParams => {
      if (currentPage > 1) {
        searchParams.delete('page');
      }
      if (dateRange === 'all_time') {
        searchParams.delete('date_range');
      } else {
        searchParams.set('date_range', dateRange as string);
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleExportCsvButtonPress = async () => {
    setIsExportingCsv(true);

    const exportSearchParams = new URLSearchParams(searchParams);

    exportSearchParams.delete('page');
    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/networks/educations/export${
      exportSearchParams.size === 0 ? '' : `?${exportSearchParams}`
    }`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { organization } = user.organization_memberships.find(
        membership => membership.member_role === 'network_manager'
      )!;

      const formattedDate = Temporal.Now.plainDateISO().toLocaleString('en-US');
      const organizationName = organization.name.replace(/\s/g, '');

      await downloadCsv(
        response,
        `Violet_EducationEngagement_${organizationName}_${formattedDate}.csv`
      );
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setIsExportingCsv(false);
  };

  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    setSelectedCommunities([...communities].sort());

    setSearchParams(searchParams => {
      if (currentPage > 1) {
        searchParams.delete('page');
      }
      if (communities.length === 0) {
        searchParams.delete('communities[]');
      } else {
        searchParams.set('communities[]', [...communities].sort().join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleResourceNameChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const item = courseList.getItem(key as string) as Collection | EducationalResource | undefined;
    const value =
      (item as EducationalResource | undefined)?.fullTitle ??
      (item as Collection | undefined)?.title;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchParams(searchParams => {
        if (currentPage > 1) {
          searchParams.delete('page');
        }
        if (value === '' || value === undefined) {
          searchParams.delete('resource_name');
        } else {
          searchParams.set('resource_name', value);
        }
        searchParams.sort();
        return searchParams;
      });
    }, 500);
  };

  const handleSearchedProviderNameChange: AriaSearchFieldProps['onChange'] = value => {
    setSearchedProviderName(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchParams(searchParams => {
        if (currentPage > 1) {
          searchParams.delete('page');
        }
        if (value === '') {
          searchParams.delete('user_name');
        } else {
          searchParams.set('user_name', value);
        }
        searchParams.sort();
        return searchParams;
      });
    }, 500);
  };

  const handleSearchedProviderOrganizationIdSelectionChange: AriaComboBoxProps<object>['onSelectionChange'] =
    key => {
      // `key` becomes `null` when the user deletes all text from the combo box.
      // TODO: delete the code below once React Aria specifies that `key` can be `null`.
      const organizationId = key as Key | null;

      setSearchedProviderOrganizationId(organizationId);

      setSearchParams(searchParams => {
        if (currentPage > 1) {
          searchParams.delete('page');
        }
        if (organizationId === null) {
          searchParams.delete('organizations[]');
        } else {
          searchParams.set('organizations[]', organizationId as string);
        }
        searchParams.sort();
        return searchParams;
      });
    };

  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    const hasChangedColumn = column !== sortDescriptor.column;
    const newDirection = hasChangedColumn ? 'descending' : direction;
    setSortDescriptor({ column, direction: newDirection });

    setSearchParams(searchParams => {
      if (currentPage > 1) {
        searchParams.delete('page');
      }
      searchParams.set('order_by[column]', column as string);
      searchParams.set('order_by[dir]', { ascending: 'asc', descending: 'desc' }[newDirection!]);
      searchParams.sort();
      return searchParams;
    });
  };

  const setPage = (page: number) => {
    setCurrentPage(page);

    setSearchParams(searchParams => {
      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', String(page));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleResourceTypeToggling: AriaSwitchProps['onChange'] = value => {
    setIsCourseView(value);
    /* reset all other filters when toggling between course and collection views */
    setCurrentPage(1);
    setSearchedProviderName('');
    setSearchedProviderOrganizationId('');
    courseList.setFilterText('');
    setSelectedCommunities([]);
    setSelectedDateRange('all_time');
    setSearchParams(searchParams => {
      if (currentPage > 1) {
        searchParams.delete('page');
      }
      searchParams.delete('organizations[]');
      searchParams.delete('resource_name');
      searchParams.delete('user_name');
      searchParams.delete('communities[]');
      searchParams.delete('date_range');
      searchParams.set('resource_type', value ? 'course' : 'course_collection');
      searchParams.sort();
      return searchParams;
    });
  };

  useUpdateEffect(() => {
    courseList.setFilterText('');
  }, [isCourseView]);

  /**************** API CALLS ************************/
  const { educationOverview, isFetchingNetworkEducationOverview, updateEducationOverviewFilters } =
    useGetNetworkEducationOverview({
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as NetworkEducationOverviewFilters['date_range']) ??
        undefined,
      'organizations[]':
        searchParams.get('organizations[]')?.toLowerCase()?.split(',') ?? undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    });

  const {
    education,
    isFetchingNetworkEducation,
    totalNetworkEducationPages,
    updateEducationFilters
  } = useGetNetworkEducation({
    'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
    date_range:
      (searchParams.get('date_range') as NetworkEducationOverviewFilters['date_range']) ??
      undefined,
    order_by: {
      column: searchParams.get('order_by[column]') ?? 'user_name',
      dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
    },
    'organizations[]': searchParams.get('organizations[]')?.toLowerCase()?.split(',') ?? undefined,
    page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1,
    resource_name: searchParams.get('resource_name') ?? undefined,
    resource_type:
      (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ?? 'course',
    user_name: searchParams.get('user_name') ?? undefined
  });

  useUpdateEffect(() => {
    const sharedFilters = {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as NetworkEducationOverviewFilters['date_range']) ??
        undefined,
      'organizations[]':
        searchParams.get('organizations[]')?.toLowerCase()?.split(',') ?? undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    };

    updateEducationFilters({
      ...sharedFilters,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'user_name',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
    });

    updateEducationOverviewFilters({
      ...sharedFilters
    });
  }, [searchParams]);

  useEffectOnce(() => {
    // Set initial text from URL params
    courseList.setFilterText(searchParams.get('resource_name') ?? '');
  });

  return (
    <>
      <PageTitle
        description="Explore the list of currently active providers expanding their cultural competence skills through targeted educational courses on Violet's platform. To view engagement metrics at an organizational level, apply filters based on provider organization."
        title="Education engagement"
        titleVariant="h1"
      />
      <Filters
        actionButton={
          <Button
            aria-label="Export csv of all education engagement history"
            data-cy="export-csv-button"
            isLoading={isExportingCsv}
            onPress={handleExportCsvButtonPress}
            trailingIcon={ExportIcon}
          >
            Export CSV
          </Button>
        }
        headerActions={
          <Switch
            aria-label="View collections or courses"
            data-cy="resource-type-toggle"
            isSelected={isCourseView}
            offLabel="Collection view"
            onChange={handleResourceTypeToggling}
            onLabel="Course view"
          />
        }
      >
        <ComboBox
          aria-label={`${isCourseView ? 'Course' : 'Collection'} name`}
          data-cy="search-by-resource-name-field"
          icon={SearchIcon}
          inputValue={courseList.filterText}
          items={courseList.items as Collection[] | EducationalResource[]}
          onInputChange={courseList.setFilterText}
          onSelectionChange={handleResourceNameChange}
          placeholder={`${isCourseView ? 'Course' : 'Collection'} name`}
        >
          {item => (
            <Item
              key={(item as EducationalResource).id}
              textValue={(item as EducationalResource).fullTitle || (item as Collection).title}
            >
              {(item as EducationalResource).fullTitle || (item as Collection).title}
            </Item>
          )}
        </ComboBox>
        <SearchField
          aria-label="Search by provider name"
          data-cy="search-by-provider-name-field"
          onChange={handleSearchedProviderNameChange}
          placeholder="Provider name"
          value={searchedProviderName}
        />
        <NetworkOrganizationFilter
          aria-label="Search by provider organization"
          data-cy="search-by-provider-organization-field"
          handleSelectionChange={
            handleSearchedProviderOrganizationIdSelectionChange as (value: Key | null) => void
          }
          placeholder="Provider organization"
          selectedKey={searchedProviderOrganizationId!}
        />
        <Select
          aria-label="Filter by date range"
          data-cy="filter-by-date-range-field"
          onSelectionChange={handleDateRangeSelectionChange}
          // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
          //@ts-expect-error
          selectedKey={selectedDateRange}
        >
          <Item key="last_30_days">Last 30 days</Item>
          <Item key="last_90_days">Last 90 days</Item>
          <Item key="year_to_date">Year to date</Item>
          <Item key="last_12_months">Last 12 months</Item>
          <Item key="all_time">All time</Item>
        </Select>
        <CommunityFilter filterLabelText="Community of focus">
          <CheckboxGroup
            aria-labelledby="filter-label"
            direction="horizontal"
            onChange={handleFilterByCommunityChange}
            value={selectedCommunities}
          >
            {COMMUNITIES.map(community => (
              <GroupCheckbox
                key={community}
                data-cy={`community-${community}`}
                value={community.toLowerCase()}
              >
                {community}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
        </CommunityFilter>
      </Filters>
      {isFetchingNetworkEducation || isFetchingNetworkEducationOverview ? (
        <S.SpinnerWrapper $height="20rem">
          <Spinner />
        </S.SpinnerWrapper>
      ) : education.length === 0 ? (
        <TableEmptyState />
      ) : (
        <>
          <DataReportRow layout="quarters">
            <DataHighlight
              data-cy="network-resources-completed"
              detailText={`${isCourseView ? 'Courses' : 'Collections'} completed`}
              numberHighlight={formatter.format(educationOverview?.educationCompletedCount ?? 0)}
              variant="small"
            />
            <DataHighlight
              data-cy="network-hours-learning"
              detailText="Hours learning"
              numberHighlight={formatter.format(Number(educationOverview?.educationHours ?? 0))}
              variant="small"
            />
            <DataHighlight
              data-cy="network-average-course-rating"
              detailText="Average course rating"
              isEmptyState={educationOverview?.averageCourseScore! === 0}
              numberHighlight={
                educationOverview?.averageCourseScore! > 0
                  ? `${educationOverview?.averageCourseScore!}/5`
                  : 'N/A'
              }
              variant="small"
            />
            <DataHighlight
              data-cy="network-ce-cme-credits-earned"
              detailText="CE/CME credits earned"
              numberHighlight={formatter.format(educationOverview?.ceCreditsEarned ?? 0)}
              variant="small"
            />
          </DataReportRow>
          <S.Table
            aria-label="Table of network providers"
            centeredIndexes={[6, 7, 8]}
            data-cy="network-providers-table"
            hasLinkedRows
            onSortChange={handleTableSortChange}
            sortDescriptor={sortDescriptor}
          >
            <TableHeader>
              <Column
                key="user_name"
                allowsSorting
              >
                NAME
              </Column>
              <Column
                key="user_organization"
                allowsSorting
              >
                ORGANIZATION
              </Column>
              <Column
                key="resource_name"
                allowsSorting
              >
                {isCourseView ? 'COURSE' : 'COLLECTION'}
              </Column>
              <Column>PUBLISHER</Column>
              <Column
                key="completed_at"
                allowsSorting
              >
                <TableNumberCell alignRight>COMPLETION DATE</TableNumberCell>
              </Column>
              <Column>
                <TableNumberCell alignRight>HOURS</TableNumberCell>
              </Column>
              <Column>BIPOC</Column>
              <Column>LGBQ</Column>
              <Column>TGNC</Column>
            </TableHeader>
            <TableBody>
              {education.map(
                ({
                  communities,
                  completionDate,
                  currentlyPublished,
                  hours,
                  id,
                  name,
                  organizations,
                  publisher,
                  resourceId,
                  user
                }) => (
                  <Row
                    key={id}
                    data-cy="education-row"
                  >
                    <Cell>
                      <S.TruncatedDiv ref={fullNameCellRef}>
                        <S.Tooltip
                          content={<S.TooltipText>{user.fullName}</S.TooltipText>}
                          delay={0}
                        >
                          <S.TruncatedButton
                            style={{ maxWidth: fullNameCellRef.current?.clientWidth }}
                          >
                            {user.fullName}
                          </S.TruncatedButton>
                        </S.Tooltip>
                      </S.TruncatedDiv>
                    </Cell>
                    <Cell>
                      <S.TruncatedDiv ref={organizationNameRef}>
                        <S.Tooltip
                          content={
                            <S.TooltipText>
                              {organizations !== undefined && organizations.length > 0
                                ? organizations[0].name
                                : ''}
                            </S.TooltipText>
                          }
                          delay={0}
                        >
                          <S.TruncatedButton
                            style={{ maxWidth: organizationNameRef.current?.clientWidth }}
                          >
                            {organizations !== undefined && organizations.length > 0
                              ? organizations[0].name
                              : ''}
                          </S.TruncatedButton>
                        </S.Tooltip>
                      </S.TruncatedDiv>
                    </Cell>
                    <Cell>
                      <S.TruncatedDiv
                        ref={educationNameRef}
                        style={{ maxWidth: educationNameRef.current?.clientWidth }}
                      >
                        <S.Tooltip
                          content={<S.TooltipText>{name}</S.TooltipText>}
                          delay={0}
                        >
                          {currentlyPublished ? (
                            <TableLinkedCell
                              data-cy="resource-link"
                              href={`/dashboard/education/${
                                isCourseView ? `all-education/` : `collections/`
                              }${resourceId}`}
                              target="_blank"
                            >
                              {name}
                            </TableLinkedCell>
                          ) : (
                            <S.TruncatedButton
                              style={{ maxWidth: educationNameRef.current?.clientWidth }}
                            >
                              {name}
                            </S.TruncatedButton>
                          )}
                        </S.Tooltip>
                      </S.TruncatedDiv>
                    </Cell>
                    <Cell>
                      <S.TruncatedDiv ref={publisherNameRef}>
                        <S.Tooltip
                          content={<S.TooltipText>{publisher}</S.TooltipText>}
                          delay={0}
                        >
                          <S.TruncatedButton
                            style={{ maxWidth: publisherNameRef.current?.clientWidth }}
                          >
                            {publisher}
                          </S.TruncatedButton>
                        </S.Tooltip>
                      </S.TruncatedDiv>
                    </Cell>
                    <Cell>
                      <TableNumberCell>{completionDate.toLocaleString('en-US')}</TableNumberCell>
                    </Cell>
                    <Cell>
                      <TableNumberCell>{hours}</TableNumberCell>
                    </Cell>
                    <Cell>
                      {communities.includes('bipoc') && (
                        <S.CheckCircleIcon
                          aria-label="Checked"
                          role="img"
                        />
                      )}
                    </Cell>
                    <Cell>
                      {communities.includes('lgbq') && (
                        <S.CheckCircleIcon
                          aria-label="Checked"
                          role="img"
                        />
                      )}
                    </Cell>
                    <Cell>
                      {communities.includes('tgnc') && (
                        <S.CheckCircleIcon
                          aria-label="Checked"
                          role="img"
                        />
                      )}
                    </Cell>
                  </Row>
                )
              )}
            </TableBody>
          </S.Table>
          <Pagination
            currentPage={currentPage}
            setPage={setPage}
            totalPages={totalNetworkEducationPages}
          />
        </>
      )}
    </>
  );
};

export default EducationEngagement;
