import styled from 'styled-components';

interface DialogProps {
  $theme: 'dark' | 'light';
}

export const Dialog = styled.div<DialogProps>`
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  outline: none;

  ${({ $theme }) => {
    if ($theme === 'dark') {
      return `
        background-color: var(--gray-900);
      `;
    } else {
      return `
        background-color: white;
      `;
    }
  }}
`;
