import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import { AnimatePresence } from 'framer-motion';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import {
  AriaComboBoxOptions,
  AriaSelectProps,
  useNumberFormatter,
  VisuallyHidden
} from 'react-aria';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Cell,
  Column,
  Item,
  Row,
  SortDescriptor,
  SortDirection,
  TableBody,
  TableHeader,
  useAsyncList,
  useOverlayTriggerState
} from 'react-stately';
import ComboBox from 'src/components/FormFields/ComboBox';
import Select from 'src/components/FormFields/Select';
import Pagination from 'src/components/Pagination';
import Spinner from 'src/components/Spinner';
import EmptyTableState from 'src/components/Table/EmptyState';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import UnstyledButton from 'src/components/UnstyledButton';
import useUser from 'src/hooks/useUser';
import { sortedParams } from 'src/utils/sortedParams';

import useGetOrganizationAssignments from '../../hooks/useGetOrganizationAssignments';
import { Collection, generateCollection } from '../../utils';

import AssignmentModal from './Assignment';
import * as S from './styles';

const MyOrgAssignments = () => {
  const location = useLocation();
  const { assignmentId } = useParams<{ assignmentId?: string }>();
  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const navigate = useNavigate();
  const isAssignmentReportingEnabled = useFeatureFlagEnabled('assignment_reporting');
  const hasAssignmentDetailsEnabled = useFeatureFlagEnabled('assignment_details_reporting');

  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });

  const {
    close: closeAssignment,
    isOpen: assignmentModalIsOpen,
    open: openAssignment
  } = useOverlayTriggerState({
    defaultOpen: assignmentId !== undefined
  });

  const organization = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization;
  const organizationId = organization?.id;

  const [previousUrl, setPreviousUrl] = useState<string>();
  const [currentAssignmentId, setCurrentAssignmentId] = useState<string | undefined>(assignmentId);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchByResourceName, setSearchByResourceName] = useState(
    searchParams.get('resource_name') ?? ''
  );
  const [selectedStatus, setSelectedStatus] = useState<'active' | 'all' | 'inactive'>(
    searchParams.get('active') !== null
      ? searchParams.get('active') === 'true'
        ? 'active'
        : 'inactive'
      : 'all'
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('order_by[column]') ?? 'end_date',
    direction:
      searchParams.get('order_by[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);

  const { assignments, totalAssignmentPages, updateAssignmentsFilters } =
    useGetOrganizationAssignments(organizationId ?? '', {
      active:
        searchParams.get('active') !== null
          ? (searchParams.get('active') as 'false' | 'true')
          : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'end_date',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1,
      resource_name: searchParams.get('resource_name') ?? undefined
    });

  const resourceList = useAsyncList({
    async load({ filterText, signal }) {
      const queryString = filterText !== '' ? `&name=${filterText}` : '';
      const res = await fetch(
        `${process.env.REACT_APP_API_V2_BASE_PATH}/course_collections?per_page=10${queryString}`,
        { ...fetchOptions, signal }
      );
      const json = (await res.json()) as APICourseCollections | APICourses;

      const resources = (json as APICourseCollections).data.map(fields =>
        generateCollection(fields, user)
      );

      return {
        items: resources
      };
    }
  });

  const handleResourceNameChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const item = resourceList.getItem(key as string) as Collection | undefined;
    const value = item?.title;
    setSearchByResourceName(value ?? '');
    setPage(1);
    setSearchParams(sortedParams('resource_name', value ?? ''));
  };

  const handleStatusSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    setSelectedStatus(key as 'active' | 'all' | 'inactive');
    setPage(1);
    setSearchParams(
      sortedParams(
        'active',
        key.toString() === 'all' ? '' : key.toString() === 'active' ? 'true' : 'false'
      )
    );
  };

  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    const hasChangedColumn = column !== sortDescriptor.column;
    const newDirection = hasChangedColumn ? 'descending' : direction;
    setSortDescriptor({ column, direction: newDirection });

    setSearchParams(searchParams => {
      if (page > 1) {
        searchParams.delete('page');
      }
      searchParams.set('order_by[column]', column as string);
      searchParams.set('order_by[dir]', { ascending: 'asc', descending: 'desc' }[newDirection!]);
      searchParams.sort();
      return searchParams;
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setSearchParams(sortedParams('page', page > 1 ? String(page) : ''));
  };

  const openAssignmentModal = (assignmentId: string) => {
    setPreviousUrl(`${location.pathname}${location.search}${location.hash}`);
    setCurrentAssignmentId(assignmentId);
    /*
      We have to use pushState here because React Router navigate
      triggers unnecessary animation refreshes
    */
    window.history.pushState(
      null,
      document.title,
      `/dashboard/my-organization/assignments/${assignmentId}?user_page=1&user_order_by%5Bcolumn%5D=completed_at&user_order_by%5Bdir%5D=desc`
    );
    openAssignment();
  };

  const closeAssignmentModal = () => {
    /*
      We have to use window.history here because of using pushState above
    */
    if (
      window.location.pathname.includes(
        `/dashboard/my-organization/assignments/${currentAssignmentId}`
      )
    ) {
      if (previousUrl === undefined) {
        // default = no previous history
        window.history.pushState(
          null,
          document.title,
          '/dashboard/my-organization/assignments?order_by%5Bcolumn%5D=end_date&order_by%5Bdir%5D=desc&page=1'
        );
      } else {
        window.history.pushState(null, document.title, previousUrl);
      }
    }

    closeAssignment();
    setCurrentAssignmentId(undefined);
    setPreviousUrl(undefined);
  };

  useEffect(() => {
    if (isAssignmentReportingEnabled === false) {
      navigate(`/dashboard/my-organization/education-engagement`, { replace: true });
    }
  }, [isAssignmentReportingEnabled, navigate]);

  useEffect(() => {
    /* Add listener for if URL changes
     *  to know if details modal should be closed
     *  when user navigates back in browser
     */
    const eventListener = () => {
      if (!location.pathname.includes('assignments/')) {
        closeAssignment();
        setCurrentAssignmentId(undefined);
        setPreviousUrl(undefined);
      }
    };
    window.addEventListener('popstate', eventListener);

    return () => {
      // Clear listener when modal is unmounted
      window.removeEventListener('popstate', eventListener);
    };
    // run once -- rerunning this effect will break the listener
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateAssignmentsFilters({
      active:
        searchParams.get('active') !== null
          ? (searchParams.get('active') as 'false' | 'true')
          : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'end_date',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1,
      resource_name: searchParams.get('resource_name') ?? undefined
    });

    if (searchParams.get('page') !== null && page !== Number(searchParams.get('page'))) {
      setPage(Number(searchParams.get('page')));
    } else if (searchParams.get('page') === null && page !== 1) {
      setPage(1);
    }
    if (searchParams.get('resource_name') !== searchByResourceName) {
      setSearchByResourceName(searchParams.get('resource_name') ?? '');
    }
    if (searchParams.get('active') !== null && selectedStatus !== 'all') {
      setSelectedStatus(
        (searchParams.get('active') as 'false' | 'true') === 'true' ? 'active' : 'inactive'
      );
    } else if (searchParams.get('active') === null && selectedStatus !== 'all') {
      setSelectedStatus('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (assignments === undefined) {
    return <Spinner withWrapper />;
  }

  return (
    <>
      <S.AssignmentsPageTitle
        description="This table displays users in your organization and the educations they completed."
        title="Assignments"
        titleVariant="h1"
      />
      <S.AssignmentFilters>
        <ComboBox
          aria-label="Education name"
          data-cy="search-by-resource-name-input"
          icon={SearchIcon}
          inputValue={searchByResourceName}
          items={resourceList.items as Collection[]}
          onInputChange={value => {
            resourceList.setFilterText(value);
            setSearchByResourceName(value);
          }}
          onSelectionChange={handleResourceNameChange}
          placeholder="Education name"
        >
          {item => (
            <Item
              key={(item as Collection).id}
              textValue={(item as Collection).title}
            >
              {(item as Collection).title}
            </Item>
          )}
        </ComboBox>
        <Select
          aria-label="Filter by assignment status"
          data-cy="status-filter"
          onSelectionChange={handleStatusSelectionChange}
          placeholder="Assignment status"
          selectedKey={selectedStatus}
        >
          <Item key="all">All statuses</Item>
          <Item key="active">Active</Item>
          <Item key="inactive">Inactive</Item>
        </Select>
      </S.AssignmentFilters>
      <S.AssignmentsTable
        aria-label="Table listing all assignments for your organization."
        className={hasAssignmentDetailsEnabled === true ? 'details-enabled' : 'details-disabled'}
        data-cy="assignments-table"
        hasLinkedRows
        onRowClick={
          hasAssignmentDetailsEnabled === true ? (id: string) => openAssignmentModal(id) : undefined
        }
        onSortChange={handleTableSortChange}
        renderEmptyState={() => (
          <EmptyTableState
            colSpan={10}
            message="No assignments have been found."
          />
        )}
        sortDescriptor={sortDescriptor}
      >
        <TableHeader>
          <Column key="open">
            {hasAssignmentDetailsEnabled === true ? (
              <VisuallyHidden>View assignment</VisuallyHidden>
            ) : null}
          </Column>
          <Column
            key="resource_name"
            allowsSorting
          >
            Name
          </Column>
          <Column
            key="resource_type"
            allowsSorting
          >
            Type
          </Column>
          <Column
            key="start_date"
            allowsSorting
          >
            <TableNumberCell alignRight>Start date</TableNumberCell>
          </Column>
          <Column
            key="end_date"
            allowsSorting
          >
            <TableNumberCell alignRight>End date</TableNumberCell>
          </Column>
          <Column
            key="active"
            allowsSorting
          >
            Status
          </Column>
          <Column key="total_members_count">
            <TableNumberCell alignRight>Assignees #</TableNumberCell>
          </Column>
          <Column key="complete_count">
            <TableNumberCell alignRight>Completions</TableNumberCell>
          </Column>
          <Column key="incomplete_count">
            <TableNumberCell alignRight>Incomplete</TableNumberCell>
          </Column>
          <Column key="percentage_complete">
            <TableNumberCell alignRight>% complete</TableNumberCell>
          </Column>
        </TableHeader>
        <TableBody>
          {assignments.map(assignment => (
            <Row
              key={assignment.id}
              data-cy="assignment-row"
            >
              <Cell>
                {hasAssignmentDetailsEnabled === true && (
                  <S.OpenDetailsButtonContainer>
                    <UnstyledButton
                      data-cy="open-assignment-details-btn"
                      onPress={() => openAssignmentModal(assignment.id)}
                    >
                      <S.LinkOutIcon />
                    </UnstyledButton>
                  </S.OpenDetailsButtonContainer>
                )}
              </Cell>
              <Cell>
                <S.Tooltip
                  content={<S.TooltipText>{assignment.resourceName}</S.TooltipText>}
                  delay={0}
                >
                  {hasAssignmentDetailsEnabled === true ? (
                    <S.TruncatedCell
                      data-cy="resource-name"
                      onPress={() => openAssignmentModal(assignment.id)}
                    >
                      {assignment.resourceName}
                    </S.TruncatedCell>
                  ) : (
                    <S.TruncatedLinkCell
                      data-cy="resource-link"
                      href={encodeURI(
                        `/dashboard/my-organization/education-engagement?resource_type=${assignment.resourceType === 'Course' ? 'course' : 'course_collection'}&resource_name=${assignment.resourceName}`
                      )}
                    >
                      {assignment.resourceName}
                    </S.TruncatedLinkCell>
                  )}
                </S.Tooltip>
              </Cell>
              <Cell data-cy="resource-type">
                {assignment.resourceType === 'CourseCollection' ? 'Collection' : 'Course'}
              </Cell>
              <Cell data-cy="start-date">
                <TableNumberCell>
                  {assignment.startDate !== undefined
                    ? assignment.startDate.toLocaleString('en-US')
                    : ''}
                </TableNumberCell>
              </Cell>
              <Cell data-cy="end-date">
                <TableNumberCell>
                  {assignment.endDate !== undefined
                    ? assignment.endDate.toLocaleString('en-US')
                    : ''}
                </TableNumberCell>
              </Cell>
              <Cell data-cy="status">
                <S.StatusLabel>
                  <S.StatusCircle className={assignment.isActive ? 'active' : 'inactive'} />
                  {assignment.isActive ? 'Active' : 'Inactive'}
                </S.StatusLabel>
              </Cell>
              <Cell data-cy="total-members-count">
                <TableNumberCell>
                  {assignment.totalMemberCount === 1 ? (
                    <S.MemberTooltipTrigger
                      content={
                        <S.TooltipText data-cy="member-name-tooltip">
                          {assignment.users[0].fullName}
                        </S.TooltipText>
                      }
                      delay={0}
                      theme="light"
                    >
                      <S.MemberTooltipButton data-cy="member-name-tooltip-trigger">
                        <S.MemberIcon />
                        {commaNumFormatter.format(assignment.totalMemberCount)}
                      </S.MemberTooltipButton>
                    </S.MemberTooltipTrigger>
                  ) : (
                    <>{commaNumFormatter.format(assignment.totalMemberCount)}</>
                  )}
                </TableNumberCell>
              </Cell>
              <Cell data-cy="completed-count">
                <TableNumberCell>
                  {commaNumFormatter.format(assignment.completedCount)}
                </TableNumberCell>
              </Cell>
              <Cell data-cy="incomplete-count">
                <TableNumberCell>
                  {commaNumFormatter.format(assignment.incompleteCount)}
                </TableNumberCell>
              </Cell>
              <Cell data-cy="percentage-complete-count">
                <TableNumberCell>{`${assignment.percentageCompleted.toString()}%`}</TableNumberCell>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </S.AssignmentsTable>
      <Pagination
        currentPage={page}
        setPage={handlePageChange}
        totalPages={totalAssignmentPages}
      />
      <AnimatePresence>
        {assignmentModalIsOpen && currentAssignmentId !== undefined && (
          <AssignmentModal
            assignmentId={currentAssignmentId}
            close={closeAssignmentModal}
            organizationId={organizationId ?? ''}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default MyOrgAssignments;
