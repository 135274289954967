import { OverlayContainer } from 'react-aria';

import ModalDialog from '../ModalDialog';

import * as S from './styles';

interface Props {
  close: () => void;
}

const ErrorModalDialog = ({ close }: Props) => {
  const handleCloseButtonPress = () => {
    close();
  };

  const handleModalDialogClose = () => {
    close();
  };

  return (
    <OverlayContainer>
      <ModalDialog
        data-cy="error-modal-dialog"
        isDismissable
        isOpen
        onClose={handleModalDialogClose}
        title="An error has occurred"
      >
        <S.Text>
          Please retry your last action. If this doesn’t work then please reach out to the team
          using the chat or via email at{' '}
          <S.Anchor
            data-cy="email-support-link"
            href="mailto:support@joinviolet.com"
          >
            support@joinviolet.com
          </S.Anchor>{' '}
          and we’ll fix this for you.
        </S.Text>
        <S.Button
          data-cy="close-button"
          onPress={handleCloseButtonPress}
          size="large"
          variant="outline"
        >
          Close
        </S.Button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export default ErrorModalDialog;
