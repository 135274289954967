import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const CommunityFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  padding: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
    align-items: start;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-column: span 2;
  }
`;

export const FilterLabel = styled.span`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 0.5rem;
  }
`;
