import ButtonWithMenu from 'src/components/Buttons/ButtonWithMenu';
import DataContainer from 'src/components/Reporting/DataContainer';
import Table from 'src/components/Table';
import NetworkOverviewReport from 'src/pages/Reports/Network/NetworkOverviewReport';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

export const CenterColumn = styled.div`
  text-align: center;
  width: 100%;
`;

export const CenterDataCell = styled.div`
  text-align: center;
  width: 100%;
`;

export const DataWrapper = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const EmptyState = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
`;

export const ExportButtonWithMenu = styled(ButtonWithMenu)`
  height: 2.25rem;
  width: 6.625rem;
`;

// this is a hack to allow us to render the report offscreen and then download it
export const HiddenOffscreenReportRender = styled.div`
  left: -2000px;
  overflow: visible;
  position: absolute;
  top: -2000px;
`;

export const OverviewLocationTable = styled(Table)`
  tr th:nth-child(2) {
    > div {
      text-align: end;
    }
  }

  tr th:nth-child(3) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    > div {
      text-align: end;
    }
  }

  tr th:nth-child(4) {
    background-color: var(--light-purple-50);

    > div {
      text-align: end;
    }
  }

  tr th:nth-child(5) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    > div {
      text-align: end;
    }
  }
`;

export const OverviewReport = styled(NetworkOverviewReport)`
  width: 100%;
`;

export const ProviderOrganizationsTable = styled(Table)`
  tr th:nth-child(2) {
    > div {
      justify-content: end;
    }
  }
`;

export const SingleTable = styled(DataContainer)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }
`;
