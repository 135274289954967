import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import { Key, useState } from 'react';
import { AriaComboBoxProps, useFilter } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import ComboBox from 'src/components/FormFields/ComboBox';
import PageTitle from 'src/components/PageTitle';
import DemographicGraph from 'src/components/Reporting/DemographicGraph';
import Spinner from 'src/components/Spinner';
import Filters from 'src/components/Table/Filters';
import TagGroup from 'src/components/TagGroup';
import { NPI_SPECIALTIES } from 'src/pages/constants';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { useUpdateEffect } from 'usehooks-ts';

import useGetNetworkDiversity from '../../hooks/useGetNetworkDiversity';
import { NetworkOrganizationMember } from '../../utils';
import NetworkOrganizationFilter from '../Components/NetworkOrganizationFilter';

import * as S from './styles';

const Demographics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { contains } = useFilter({ sensitivity: 'base' });

  const [selectedSpecialtiesFilter, setSelectedSpecialtiesFilter] = useState<string[]>(
    searchParams.get('specialties[]')?.split(',') ?? []
  );
  const [selectedOrganizationsFilter, setSelectedOrganizationsFilter] = useState<string[]>(
    searchParams.get('organizations[]')?.split(',') ?? []
  );

  const {
    isFetching: isFetchingNetworkDiversity,
    networkDiversity,
    updateNetworkDiversityFilters
  } = useGetNetworkDiversity({
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? undefined,
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? undefined
  });

  // Update URL with params
  useUpdateEffect(() => {
    updateNetworkDiversityFilters({
      'organizations[]': searchParams.get('organizations[]')?.split(',') ?? undefined,
      'specialties[]': searchParams.get('specialties[]')?.split(',') ?? undefined
    });
  }, [searchParams]);

  const handleSpecialtiesFilterChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    setSelectedSpecialtiesFilter([value as string]);
    setSearchParams(searchParams => {
      if (!isNonEmptyString(value)) {
        searchParams.delete('specialties[]');
      } else {
        searchParams.set('specialties[]', value as string);
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleOrganizationsFilterChange: AriaComboBoxProps<
    NetworkOrganizationMember['id']
  >['onSelectionChange'] = value => {
    setSelectedOrganizationsFilter([value as string]);
    setSearchParams(searchParams => {
      if (!isNonEmptyString(value)) {
        searchParams.delete('organizations[]');
      } else {
        searchParams.set('organizations[]', value as string);
      }
      searchParams.sort();
      return searchParams;
    });
  };

  return (
    <>
      <PageTitle
        description="Network demographic data is shown in an aggregated format to ensure provider privacy.  This information is only available for active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
        title="Diversity"
        titleVariant="h1"
      />
      <Filters>
        <NetworkOrganizationFilter
          aria-label="Search by provider organization"
          data-cy="organizations-filter"
          handleSelectionChange={handleOrganizationsFilterChange as (value: Key | null) => void}
          placeholder="Provider organization"
          selectedKey={selectedOrganizationsFilter[0] as Key}
        />
        <ComboBox
          aria-label="Search by specialty"
          data-cy="specialties-filter"
          filter={contains}
          icon={SearchIcon}
          onSelectionChange={handleSpecialtiesFilterChange}
          placeholder="Specialty"
          selectedKey={selectedSpecialtiesFilter[0]}
        >
          {NPI_SPECIALTIES.map(specialty => (
            <Item key={specialty}>{specialty}</Item>
          ))}
        </ComboBox>
      </Filters>
      {isFetchingNetworkDiversity ? (
        <Spinner withWrapper />
      ) : networkDiversity ? (
        <S.ChartsContainer>
          <S.ChartWrapper data-cy="race-ethnicity-data">
            <S.ChartTitle>Race & ethnicity</S.ChartTitle>
            <DemographicGraph
              data={networkDiversity.raceEthnicity}
              type="raceEthnicity"
            />
          </S.ChartWrapper>
          <S.ChartWrapper data-cy="gender-identity-data">
            <S.ChartTitle>Gender identity</S.ChartTitle>
            <DemographicGraph
              data={networkDiversity.genderIdentity}
              type="genderIdentity"
            />
          </S.ChartWrapper>
          <S.ChartWrapper data-cy="sexual-orientation-data">
            <S.ChartTitle>Sexual orientation</S.ChartTitle>
            <DemographicGraph
              data={networkDiversity.sexualOrientation}
              type="sexualOrientation"
            />
          </S.ChartWrapper>
          <S.ChartWrapper>
            <S.ChartTitle>Care delivery languages</S.ChartTitle>
            {networkDiversity.language.length > 0 ? (
              <>
                <S.LanguageDescription id="language-description">
                  The percentage of providers fluent in each of the following languages:
                </S.LanguageDescription>
                <TagGroup
                  aria-labelledby="language-description"
                  data-cy="languages-data"
                  variant="light"
                >
                  {networkDiversity.language.map(item => (
                    <Item
                      key={item.label}
                      textValue={`${item.percentage}% ${item.label}`}
                    >
                      <S.LanguagePercentage>{item.percentage}%</S.LanguagePercentage>
                      {item.label}
                    </Item>
                  ))}
                </TagGroup>
              </>
            ) : (
              <S.LanguageDescription data-cy="languages-data">
                No known team members speak a language other than English.
              </S.LanguageDescription>
            )}
          </S.ChartWrapper>
        </S.ChartsContainer>
      ) : (
        <S.NoData data-cy="no-data">No data available.</S.NoData>
      )}
    </>
  );
};

export default Demographics;
