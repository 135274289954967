import { useNumberFormatter } from 'react-aria';
import { Cell, Column, Row, SortDescriptor, TableBody, TableHeader } from 'react-stately';
import Badge from 'src/components/Badge';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { NetworkOrganizationMember } from 'src/pages/Dashboard/utils';

import * as Shared from '../styles';

import * as S from './styles';

interface Props {
  currentPageOrganizations: number;
  handleOrganizationsTableSortChange?: (descriptor: SortDescriptor) => void;
  handleSetPageOrganizations: (page: number) => void;
  networkOrganizationsTotalPages: number;
  networkOrgMembers: NetworkOrganizationMember[];
  sortDescriptorOrganizations?: SortDescriptor;
}

export const NetworkMembersTable = ({
  currentPageOrganizations,
  handleOrganizationsTableSortChange,
  handleSetPageOrganizations,
  networkOrganizationsTotalPages,
  networkOrgMembers,
  sortDescriptorOrganizations
}: Props) => {
  const commaNumFormatter = useNumberFormatter({
    maximumFractionDigits: 0,
    useGrouping: true
  });
  return (
    <>
      <S.NetworkOrganizationsTable
        aria-label="Table of provider organizations"
        data-cy="network-organization-members-table"
        onSortChange={handleOrganizationsTableSortChange}
        sortDescriptor={sortDescriptorOrganizations}
      >
        <TableHeader>
          <Column
            key="name"
            allowsSorting
          >
            Provider Organization
          </Column>
          <Column
            key="total_providers"
            allowsSorting
          >
            <TableNumberCell alignRight>Provider count</TableNumberCell>
          </Column>
          <Column>
            <Shared.CenterColumn>On Violet</Shared.CenterColumn>
          </Column>
          <Column
            key="bipoc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>BIPOC inclusivity</TableNumberCell>
          </Column>
          <Column
            key="lgbq_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>LGBQ inclusivity</TableNumberCell>
          </Column>
          <Column
            key="tgnc_inclusivity"
            allowsSorting
          >
            <TableNumberCell alignRight>TGNC inclusivity</TableNumberCell>
          </Column>
        </TableHeader>
        <TableBody>
          {networkOrgMembers.map(member => (
            <Row
              key={member.id}
              data-cy="network-organization-member-row"
            >
              <Cell>{member.name}</Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-total-providers">
                  {commaNumFormatter.format(member.totalProviders)}
                </TableNumberCell>
              </Cell>
              <Cell>
                <Shared.CenterColumn data-cy="organization-member-on-violet">
                  <Badge
                    color={member.onViolet ? 'green' : 'red'}
                    size="small"
                  >
                    {member.onViolet ? 'Yes' : 'No'}
                  </Badge>
                </Shared.CenterColumn>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-bipoc-inclusivity-percentage">
                  {`${member.inclusivityPercentages.bipoc}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-lgbq-inclusivity-percentage">
                  {`${member.inclusivityPercentages.lgbq}%`}
                </TableNumberCell>
              </Cell>
              <Cell>
                <TableNumberCell data-cy="organization-member-tgnc-inclusivity-percentage">
                  {`${member.inclusivityPercentages.tgnc}%`}
                </TableNumberCell>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </S.NetworkOrganizationsTable>
      <Shared.TablePagination
        currentPage={currentPageOrganizations}
        setPage={handleSetPageOrganizations}
        totalPages={networkOrganizationsTotalPages}
      />
    </>
  );
};
