import { PropsWithChildren } from 'react';
import PopoverTrigger from 'src/components/PopoverTrigger';

import * as S from './styles';

interface Props extends PropsWithChildren {
  className?: string;
}

const HelpPopoverTrigger = ({ children, className }: Props) => (
  <PopoverTrigger content={<S.PopoverContent>{children}</S.PopoverContent>}>
    <S.PopoverButton className={className}>
      Here’s what to add.
      <S.HelpIcon />
    </S.PopoverButton>
  </PopoverTrigger>
);

export default HelpPopoverTrigger;
