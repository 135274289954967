import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import * as Sentry from '@sentry/react';
import { useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaComboBoxOptions,
  AriaSearchFieldProps,
  AriaSelectProps,
  AriaSwitchProps,
  useNumberFormatter
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import {
  Cell,
  Column,
  Item,
  Row,
  SortDescriptor,
  SortDirection,
  TableBody,
  TableHeader,
  useAsyncList
} from 'react-stately';
import Button from 'src/components/Buttons/Button';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import ComboBox from 'src/components/FormFields/ComboBox';
import SearchField from 'src/components/FormFields/SearchField';
import Select from 'src/components/FormFields/Select';
import Switch from 'src/components/FormFields/Switch';
import PageTitle from 'src/components/PageTitle';
import Pagination from 'src/components/Pagination';
import DataContainer from 'src/components/Reporting/DataContainer';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Table from 'src/components/Table';
import Filters from 'src/components/Table/Filters';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { COMMUNITIES } from 'src/pages/constants';
import { capitalize } from 'src/utils/stringTransformations';
import { useUpdateEffect } from 'usehooks-ts';

import Spinner from '../../../../components/Spinner';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';
import { downloadCsv } from '../../../../utils/downloadCsv';
import TableEmptyState from '../../components/TableEmptyState';
import useGetOrganizationEducation from '../../hooks/useGetOrganizationEducation';
import useGetOrganizationEducationOverview, {
  OrganizationEducationOverviewFilters
} from '../../hooks/useGetOrganizationEducationOverview';
import { USER_TYPES } from '../../hooks/useGetOrganizationUsers';
import {
  Collection,
  EducationalResource,
  generateCollection,
  generateEducationalResource
} from '../../utils';

import * as S from './styles';

const EducationEngagement = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const formatter = useNumberFormatter();
  const [searchParams, setSearchParams] = useSearchParams();

  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const organization = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization;
  const organizationName = organization?.name;
  const organizationId = organization?.id;

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [isExporting, setIsExporting] = useState(false);

  /* -------------------- FILTERS AND URL PARAM STATES -------------------- */
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [isCourseView, setIsCourseView] = useState<boolean>(
    searchParams.get('resource_type') !== null
      ? searchParams.get('resource_type') === 'course'
      : true
  );
  const [searchByResourceName, setSearchByResourceName] = useState(
    searchParams.get('resource_name') ?? ''
  );
  const [searchByNameText, setSearchByNameText] = useState(searchParams.get('user_name') ?? '');
  const [selectedCommunities, setSelectedCommunities] = useState<string[]>(
    searchParams.get('communities[]')?.split(',') ?? []
  );
  const [selectedUserType, setSelectedUserType] = useState<string | null>(
    searchParams.get('clinical') === 'true'
      ? 'clinical'
      : searchParams.get('clinical') === 'false'
        ? 'non-clinical'
        : 'all'
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('order_by[column]') ?? 'user_name',
    direction:
      searchParams.get('order_by[dir]') === null
        ? 'ascending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });
  const [selectedDateRange, setSelectedDateRange] = useState<
    OrganizationEducationOverviewFilters['date_range'] | 'all_time'
  >(
    searchParams.get('date_range') !== null
      ? (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range'])
      : 'all_time'
  );

  const sortedParams = (key: string, value: string) => (searchParams: URLSearchParams) => {
    if (page > 1) {
      searchParams.delete('page');
    }
    if (!value) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    searchParams.sort();
    return searchParams;
  };

  /* -------------------- HANDLERS -------------------- */
  const handleExportCsvButtonPress = async () => {
    setIsExporting(true);

    const exportSearchParams = new URLSearchParams(searchParams);
    exportSearchParams.delete('page');
    const searchParamsString = exportSearchParams
      .toString()
      .replaceAll('BIPOC', 'bipoc')
      .replaceAll('LGBQ', 'lgbq')
      .replaceAll('TGNC', 'tgnc');

    const educationExportUrl: RequestInfo = `${
      process.env.REACT_APP_API_V2_BASE_PATH
    }/organizations/${organizationId}/educations/export${
      exportSearchParams.size === 0 ? '' : `?${searchParamsString}`
    }`;

    try {
      const response = await fetch(educationExportUrl, fetchOptions);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      const organizationNameNoSpaces = organizationName?.replace(/\s/g, '');
      await downloadCsv(
        response,
        `Violet_EducationEngagement_${organizationNameNoSpaces}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    setSelectedCommunities([
      ...(communities.includes(COMMUNITIES[0]) ? [COMMUNITIES[0]] : []),
      ...(communities.includes(COMMUNITIES[1]) ? [COMMUNITIES[1]] : []),
      ...(communities.includes(COMMUNITIES[2]) ? [COMMUNITIES[2]] : [])
    ]);
    setSearchParams(sortedParams('communities[]', [...communities].sort().join(',')));
  };

  const handleResourceNameChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const item = courseList.getItem(key as string) as Collection | EducationalResource | undefined;
    const value =
      (item as EducationalResource | undefined)?.fullTitle ??
      (item as Collection | undefined)?.title;
    setSearchByResourceName(value ?? '');
    setPage(1);
    setSearchParams(sortedParams('resource_name', value ?? ''));
  };

  const handleSearchByNameChange: AriaSearchFieldProps['onChange'] = value => {
    setSearchByNameText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setPage(1);
      setSearchParams(sortedParams('user_name', value));
    }, 500);
  };

  const handleUserTypeSelectionChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    setSelectedUserType(key as string);
    setSearchParams(
      sortedParams('clinical', key === 'all' ? '' : key === 'clinical' ? 'true' : 'false')
    );
  };

  const handleDateRangeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    setSelectedDateRange(key as OrganizationEducationOverviewFilters['date_range'] | 'all_time');
    setPage(1);
    setSearchParams(sortedParams('date_range', key as string));
  };

  const handleResourceTypeToggling: AriaSwitchProps['onChange'] = value => {
    setIsCourseView(value);
    /* reset all other filters when toggling between course and collection views */
    setPage(1);
    setSearchByResourceName('');
    setSearchByNameText('');
    setSelectedCommunities([]);
    setSelectedUserType('all');
    setSelectedDateRange('all_time');
    setSearchParams(searchParams => {
      if (page > 1) {
        searchParams.delete('page');
      }
      searchParams.delete('resource_name');
      searchParams.delete('user_name');
      searchParams.delete('communities[]');
      searchParams.delete('clinical');
      searchParams.delete('date_range');
      searchParams.set('resource_type', value ? 'course' : 'course_collection');
      searchParams.sort();
      return searchParams;
    });
  };

  useUpdateEffect(() => {
    courseList.setFilterText('');
  }, [isCourseView]);

  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    const hasChangedColumn = column !== sortDescriptor.column;
    const newDirection = hasChangedColumn ? 'descending' : direction;
    setSortDescriptor({ column, direction: newDirection });

    setSearchParams(searchParams => {
      if (page > 1) {
        searchParams.delete('page');
      }
      searchParams.set('order_by[column]', column as string);
      searchParams.set('order_by[dir]', { ascending: 'asc', descending: 'desc' }[newDirection!]);
      searchParams.sort();
      return searchParams;
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setSearchParams(sortedParams('page', page > 1 ? String(page) : ''));
  };

  /* -------------------- API CALLS -------------------- */
  // COURSE LIST
  const courseList = useAsyncList({
    async load({ filterText, signal }) {
      const queryString =
        filterText !== '' ? `&${isCourseView ? 'title' : 'name'}=${filterText}` : '';
      const res = await fetch(
        `${process.env.REACT_APP_API_V2_BASE_PATH}/${
          isCourseView ? 'courses' : 'course_collections'
        }?per_page=10${queryString}`,
        { ...fetchOptions, signal }
      );
      const json = (await res.json()) as APICourseCollections | APICourses;

      const resources = isCourseView
        ? (json as APICourses).data.map(generateEducationalResource)
        : (json as APICourseCollections).data.map(fields => generateCollection(fields, user));

      return {
        items: resources
      };
    }
  });

  // EDUCATION
  const { education, isFetchingOrgEducation, totalOrgEducationPages, updateEducationFilters } =
    useGetOrganizationEducation(organizationId ?? '', {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        undefined,
      is_clinical:
        searchParams.get('clinical') !== null ? searchParams.get('clinical') === 'true' : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'user_name',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    });

  // EDUCATION OVERVIEW
  const { educationOverview, isFetchingOrgEducationOverview, updateEducationOverviewFilters } =
    useGetOrganizationEducationOverview(organizationId ?? '', {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        undefined,
      is_clinical:
        searchParams.get('clinical') !== null ? searchParams.get('clinical') === 'true' : undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    });

  useUpdateEffect(() => {
    const sharedFilters = {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        undefined,
      is_clinical:
        searchParams.get('clinical') !== null ? searchParams.get('clinical') === 'true' : undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    };

    updateEducationFilters({
      ...sharedFilters,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'user_name',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
    });

    updateEducationOverviewFilters({
      ...sharedFilters
    });
  }, [searchParams]);

  return (
    <>
      <PageTitle
        title="Education engagement"
        titleVariant="h1"
      />
      <S.MarginBelowContainer>
        <Filters
          actionButton={
            <Button
              aria-label="Export csv of all education engagement history"
              data-cy="export-csv-button"
              isDisabled={isExporting}
              onPress={handleExportCsvButtonPress}
              size="regular"
              trailingIcon={ExportIcon}
            >
              {isExporting ? (
                <>
                  <Spinner />
                  Exporting...
                </>
              ) : (
                <>Export CSV</>
              )}
            </Button>
          }
          headerActions={
            <Switch
              aria-label="View collections or courses"
              data-cy="resource-type-toggle"
              isSelected={isCourseView}
              offLabel="Collection view"
              onChange={handleResourceTypeToggling}
              onLabel="Course view"
            />
          }
        >
          <ComboBox
            aria-label={`${isCourseView ? 'Course' : 'Collection'} name`}
            data-cy="search-by-resource-name-input"
            icon={SearchIcon}
            inputValue={searchByResourceName}
            items={courseList.items as Collection[] | EducationalResource[]}
            onInputChange={value => {
              courseList.setFilterText(value);
              setSearchByResourceName(value);
            }}
            onSelectionChange={handleResourceNameChange}
            placeholder={`${isCourseView ? 'Course' : 'Collection'} name`}
          >
            {item => (
              <Item
                key={(item as EducationalResource).id}
                textValue={(item as EducationalResource).fullTitle || (item as Collection).title}
              >
                {(item as EducationalResource).fullTitle || (item as Collection).title}
              </Item>
            )}
          </ComboBox>
          <SearchField
            aria-label="User name"
            data-cy="search-by-user-name-input"
            onChange={handleSearchByNameChange}
            placeholder="User name"
            value={searchByNameText}
          />
          <Select
            aria-label="Filter by user type"
            data-cy="user-type-filter"
            onSelectionChange={handleUserTypeSelectionChange}
            placeholder="Filter by user type"
            selectedKey={selectedUserType}
          >
            {USER_TYPES.map(userType => (
              <Item key={userType}>{`${capitalize(userType)} users`}</Item>
            ))}
          </Select>
          <Select
            aria-label="Filter by date range"
            data-cy="date-range-filter"
            onSelectionChange={handleDateRangeSelectionChange}
            placeholder="Filter by date range"
            selectedKey={selectedDateRange}
          >
            <Item key="last_30_days">Last 30 days</Item>
            <Item key="last_90_days">Last 90 days</Item>
            <Item key="year_to_date">Year to date</Item>
            <Item key="last_12_months">Last 12 months</Item>
            <Item key="all_time">All time</Item>
          </Select>
          <CommunityFilter filterLabelText="Community of focus">
            <CheckboxGroup
              aria-labelledby="filter-label"
              direction="horizontal"
              onChange={handleFilterByCommunityChange}
              value={selectedCommunities}
            >
              {COMMUNITIES.map(community => (
                <GroupCheckbox
                  key={community}
                  data-cy={`community-${community}`}
                  value={community}
                >
                  {community}
                </GroupCheckbox>
              ))}
            </CheckboxGroup>
          </CommunityFilter>
        </Filters>
      </S.MarginBelowContainer>

      {isFetchingOrgEducationOverview || isFetchingOrgEducation ? (
        <Spinner withWrapper />
      ) : (
        <>
          <DataContainer
            data-cy="education-engagement"
            hideBorder
          >
            <DataReportRow layout="quarters">
              <DataHighlight
                data-cy="organization-courses-complete"
                detailText={`${isCourseView ? 'Courses' : 'Collections'} completed`}
                numberHighlight={formatter.format(educationOverview?.educationCompletedCount ?? 0)}
                variant="small"
              />
              <DataHighlight
                data-cy="organization-hours-learning"
                detailText="Hours learning"
                numberHighlight={formatter.format(educationOverview?.educationHours ?? 0)}
                variant="small"
              />
              <DataHighlight
                data-cy="organization-avg-rating"
                detailText="Average course rating"
                isEmptyState={
                  educationOverview === undefined || educationOverview.averageCourseScore === 0
                }
                numberHighlight={
                  educationOverview !== undefined && educationOverview.averageCourseScore > 0
                    ? `${educationOverview.averageCourseScore}/5`
                    : 'N/A'
                }
                variant="small"
              />
              <DataHighlight
                data-cy="organization-credits-earned"
                detailText="CE/CME credits earned"
                numberHighlight={formatter.format(Number(educationOverview?.ceCreditsEarned ?? 0))}
                variant="small"
              />
            </DataReportRow>
          </DataContainer>

          {education.length === 0 ? (
            <TableEmptyState />
          ) : (
            <>
              <Table
                aria-label="Table listing all providers and the badges they've each obtained"
                centeredIndexes={[5, 6, 7]}
                data-cy="education-engagement-table"
                hasLinkedRows
                onSortChange={handleTableSortChange}
                sortDescriptor={sortDescriptor}
              >
                <TableHeader>
                  <Column
                    key="user_name"
                    allowsSorting
                  >
                    Name
                  </Column>
                  <Column
                    key="resource_name"
                    allowsSorting
                  >
                    {isCourseView ? 'Course' : 'Collection'}
                  </Column>
                  <Column
                    key="publisher"
                    allowsSorting={isCourseView}
                  >
                    Publisher
                  </Column>
                  <Column
                    key="completed_at"
                    allowsSorting
                  >
                    <TableNumberCell alignRight>Completion date</TableNumberCell>
                  </Column>
                  <Column key="hours">
                    <TableNumberCell alignRight>Hours</TableNumberCell>
                  </Column>
                  <Column key="BIPOC">BIPOC</Column>
                  <Column key="LGBQ">LGBQ</Column>
                  <Column key="TGNC">TGNC</Column>
                </TableHeader>
                <TableBody>
                  {education.map(resource => (
                    <Row
                      key={`${resource.user.fullName} ${resource.name}`}
                      data-cy="education-row"
                    >
                      <Cell>
                        <S.Tooltip
                          content={<S.TooltipText>{`${resource.user.fullName}`}</S.TooltipText>}
                          delay={0}
                        >
                          <S.TruncateLongText>{`${resource.user.fullName}`}</S.TruncateLongText>
                        </S.Tooltip>
                      </Cell>
                      <Cell>
                        <S.Tooltip
                          content={<S.TooltipText>{resource.name}</S.TooltipText>}
                          delay={0}
                        >
                          {resource.currentlyPublished ? (
                            <S.TruncatedLinkCell
                              data-cy="resource-link"
                              href={`/dashboard/education/${
                                isCourseView ? `all-education/` : `collections/`
                              }${resource.resourceId}`}
                              target="_blank"
                            >
                              {resource.name}
                            </S.TruncatedLinkCell>
                          ) : (
                            <S.TruncateLongResourceText>{resource.name}</S.TruncateLongResourceText>
                          )}
                        </S.Tooltip>
                      </Cell>
                      <Cell>
                        <S.Tooltip
                          content={<S.TooltipText>{resource.publisher}</S.TooltipText>}
                          delay={0}
                        >
                          <S.TruncateLongText>{resource.publisher}</S.TruncateLongText>
                        </S.Tooltip>
                      </Cell>
                      <Cell>
                        <TableNumberCell>
                          {resource.completionDate.toLocaleString('en-US')}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell>{resource.hours}</TableNumberCell>
                      </Cell>
                      <Cell>{resource.communities.includes('bipoc') && <S.CheckCircleIcon />}</Cell>
                      <Cell>{resource.communities.includes('lgbq') && <S.CheckCircleIcon />}</Cell>
                      <Cell>{resource.communities.includes('tgnc') && <S.CheckCircleIcon />}</Cell>
                    </Row>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                currentPage={page}
                setPage={handlePageChange}
                totalPages={totalOrgEducationPages}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default EducationEngagement;
