import { ReactComponent as ShowLessIcon } from '@material-design-icons/svg/round/expand_less.svg';
import { ReactComponent as ShowMoreIcon } from '@material-design-icons/svg/round/expand_more.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import { Key, useState } from 'react';
import { AriaCheckboxGroupProps, AriaComboBoxProps, useFilter } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item, SortDescriptor, SortDirection } from 'react-stately';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import MultiComboBox from 'src/components/FormFields/MultiComboBox';
import PageTitle from 'src/components/PageTitle';
import Spinner from 'src/components/Spinner';
import Filters from 'src/components/Table/Filters';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import { NPI_SPECIALTIES, STATES } from 'src/pages/constants';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import useGetNetworkLocations, {
  NetworkLocationsFilters
} from '../../hooks/useGetNetworkLocations';
import useGetNetworkOrgMembers from '../../hooks/useGetNetworkOrgMembers';
import useGetNetworkOverview from '../../hooks/useGetNetworkOverview';
import useGetNetworkProviders, { NetworkProviderFilters } from '../../hooks/useGetNetworkProviders';
import { NetworkOrganizationMember } from '../../utils';
import NetworkOrganizationFilter from '../Components/NetworkOrganizationFilter';

import { NetworkOverviewSection } from './NetworkOverview';
import * as S from './styles';
import { NetworkMembersTable } from './Tables/NetworkMembersTable';
import { NetworkProvidersTable } from './Tables/NetworkProvidersTable';
import { ServiceAreasTable } from './Tables/ServiceAreasTable';

const Inclusivity = () => {
  const { contains } = useFilter({ sensitivity: 'base' });
  const [showServiceArea, setShowServiceArea] = useState(true);
  const [showProviderOrganizations, setShowProviderOrganizations] = useState(true);
  const [showProviders, setShowProviders] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  /* -------------------- FILTERS AND URL PARAM STATES -------------------- */
  const [currentPageProviders, setCurrentPageProviders] = useState<number>(
    searchParams.get('page_providers') !== null ? Number(searchParams.get('page_providers')) : 1
  );
  const [sortDescriptorProviders, setSortDescriptorProviders] = useState<SortDescriptor>({
    column: searchParams.get('order_by_providers[column]') ?? 'full_name',
    direction:
      searchParams.get('order_by_providers[dir]') === null
        ? 'ascending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by_providers[dir]')!
          ] as SortDirection)
  });
  const [currentPageLocations, setCurrentPageLocations] = useState<number>(
    searchParams.get('page_locations') !== null ? Number(searchParams.get('page_locations')) : 1
  );
  const [sortDescriptorLocations, setSortDescriptorLocations] = useState<SortDescriptor>({
    column: searchParams.get('order_by_locations[column]') ?? 'provider_count',
    direction:
      searchParams.get('order_by_locations[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by_locations[dir]')!
          ] as SortDirection)
  });
  const [currentPageOrganizations, setCurrentPageOrganizations] = useState<number>(
    searchParams.get('page_organizations') !== null
      ? Number(searchParams.get('page_organizations'))
      : 1
  );
  const [sortDescriptorOrganizations, setSortDescriptorOrganizations] = useState<SortDescriptor>({
    column: searchParams.get('order_by_organizations[column]') ?? 'total_providers',
    direction:
      searchParams.get('order_by_organizations[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by_organizations[dir]')!
          ] as SortDirection)
  });
  const [stateSearchValue, setStateSearchValue] = useState<string>('');
  const [selectedStates, setSelectedStates] = useState<Set<Key>>(
    new Set(searchParams.get('states[]')?.split(',') ?? [])
  );
  const [selectedCommunitiesFilter, setSelectedCommunitiesFilter] = useState<string[]>(
    searchParams.get('benchmark_communities[]')?.split(',') ?? []
  );
  const [selectedSpecialtiesFilter, setSelectedSpecialtiesFilter] = useState<(string | null)[]>(
    searchParams.get('specialties[]')?.split(',') ?? []
  );
  const [selectedOrganizationsFilter, setSelectedOrganizationsFilter] = useState<(string | null)[]>(
    searchParams.get('organizations[]')?.split(',') ?? []
  );

  useEffectOnce(() => {
    // Set initial text from URL params
    setSelectedSpecialtiesFilter(searchParams.get('specialties[]')?.split(',') ?? []);
    setSelectedOrganizationsFilter(searchParams.get('organizations[]')?.split(',') ?? []);
    setSelectedStates(new Set(searchParams.get('states[]')?.split(',') ?? []));
  });

  const sortedParams = (key: string, value: string) => (searchParams: URLSearchParams) => {
    if (!value) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    searchParams.sort();
    return searchParams;
  };

  /* -------------------- HANDLERS -------------------- */
  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    setSelectedCommunitiesFilter([...communities].sort());
    setSearchParams(sortedParams('benchmark_communities[]', [...communities].sort().join(',')));

    handleSetPageLocations(1);
    handleSetPageProviders(1);
    handleSetPageOrganizations(1);
  };

  const handleOrganizationsFilterChange: AriaComboBoxProps<
    NetworkOrganizationMember['id']
  >['onSelectionChange'] = value => {
    setSelectedOrganizationsFilter([value as string]);
    setSearchParams(sortedParams('organizations[]', value as string));

    handleSetPageLocations(1);
    handleSetPageProviders(1);
  };

  const handleSpecialtiesFilterChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    setSelectedSpecialtiesFilter([value as string]);
    setSearchParams(sortedParams('specialties[]', value as string));

    handleSetPageLocations(1);
    handleSetPageProviders(1);
    handleSetPageOrganizations(1);
  };

  const handleStateSearchChange: AriaComboBoxProps<string>['onInputChange'] = value => {
    setStateSearchValue(value);
  };

  const handleSelectedStatesChange: AriaComboBoxProps<string>['onSelectionChange'] = (
    value: Key | null
  ) => {
    if (value === null) return;

    if (value === 'all') {
      setSelectedStates(new Set([]));
      setSearchParams(sortedParams('states[]', ''));
    } else {
      const currentStates = Array.from(selectedStates);
      const index = currentStates.findIndex(state => state === value);
      if (index === -1) {
        currentStates.push(value as string);
      } else {
        currentStates.splice(index, 1);
      }
      setSelectedStates(new Set(currentStates));
      setSearchParams(sortedParams('states[]', currentStates.join(',')));
    }

    setStateSearchValue('');
    handleSetPageLocations(1);
    handleSetPageProviders(1);
    handleSetPageOrganizations(1);
  };

  const handleProvidersTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSortDescriptorProviders({ column, direction });

    setSearchParams(searchParams => {
      searchParams.set('order_by_providers[column]', column as string);
      searchParams.set(
        'order_by_providers[dir]',
        { ascending: 'asc', descending: 'desc' }[direction!]
      );
      searchParams.sort();
      return searchParams;
    });

    handleSetPageProviders(1);
  };

  const handleLocationsTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSortDescriptorLocations({ column, direction });

    setSearchParams(searchParams => {
      searchParams.set('order_by_locations[column]', column as string);
      searchParams.set(
        'order_by_locations[dir]',
        { ascending: 'asc', descending: 'desc' }[direction!]
      );
      searchParams.sort();
      return searchParams;
    });

    handleSetPageLocations(1);
  };

  const handleOrganizationsTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSortDescriptorOrganizations({ column, direction });

    setSearchParams(searchParams => {
      searchParams.set('order_by_organizations[column]', column as string);
      searchParams.set(
        'order_by_organizations[dir]',
        { ascending: 'asc', descending: 'desc' }[direction!]
      );
      searchParams.sort();
      return searchParams;
    });

    handleSetPageOrganizations(1);
  };

  const handleSetPageProviders = (page: number) => {
    setCurrentPageProviders(page);

    if (page === 1) {
      setSearchParams(sortedParams('page_providers', ''));
    } else {
      setSearchParams(sortedParams('page_providers', String(page)));
    }
  };

  const handleSetPageLocations = (page: number) => {
    setCurrentPageLocations(page);

    if (page === 1) {
      setSearchParams(sortedParams('page_locations', ''));
    } else {
      setSearchParams(sortedParams('page_locations', String(page)));
    }
  };

  const handleSetPageOrganizations = (page: number) => {
    setCurrentPageOrganizations(page);

    if (page === 1) {
      setSearchParams(sortedParams('page_organizations', ''));
    } else {
      setSearchParams(sortedParams('page_organizations', String(page)));
    }
  };

  /* -------------------- API CALLS -------------------- */
  // OVERVIEW
  const overviewSearchParams = {
    'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
    'states[]': searchParams.get('states[]')?.split(',') ?? []
  };
  const {
    isFetching: isFetchingOverview,
    networkOverview,
    updateNetworkOverviewFilters
  } = useGetNetworkOverview(overviewSearchParams);

  // PROVIDERS
  const providersSearchParams: NetworkProviderFilters = {
    'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    order_by: {
      column: searchParams.get('order_by_providers[column]') as 'full_name' | 'organization_name',
      dir: searchParams.get('order_by_providers[dir]') === 'asc' ? 'asc' : 'desc'
    },
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
    page: parseInt(searchParams.get('page_providers') ?? '1', 10),
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
    'states[]': searchParams.get('states[]')?.split(',') ?? []
  };
  const {
    isFetching: isFetchingProviders,
    networkProviders,
    networkProvidersTotalPages,
    updateNetworkProviderFilters
  } = useGetNetworkProviders(providersSearchParams);

  // LOCATIONS
  const locationsSearchParams: NetworkLocationsFilters = {
    'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    order_by: {
      column: searchParams.get('order_by_locations[column]') as 'provider_count' | 'state',
      dir: searchParams.get('order_by_locations[dir]') === 'desc' ? 'desc' : 'asc'
    },
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
    page: parseInt(searchParams.get('page_locations') ?? '1', 10),
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
    'states[]': searchParams.get('states[]')?.split(',') ?? []
  };
  const {
    isFetching: isFetchingLocation,
    networkLocations,
    networkLocationsTotalPages,
    updateNetworkLocationFilters
  } = useGetNetworkLocations(locationsSearchParams);

  // ORGANIZATION MEMBERS
  const {
    isFetching: isFetchingProviderOrganizations,
    networkOrgMembers: networkOrganizations,
    networkOrgMembersTotalPages,
    updateNetworkOrgMembersFilters
  } = useGetNetworkOrgMembers({
    'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    order_by: {
      column:
        (searchParams.get('order_by_organizations[column]') as
          | 'bipoc_inclusivity'
          | 'lgbq_inclusivity'
          | 'tgnc_inclusivity'
          | 'total_providers'
          | null) ?? 'total_providers',
      dir: searchParams.get('order_by_organizations[dir]') === 'desc' ? 'desc' : 'asc'
    },
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
    page: parseInt(searchParams.get('page_organizations') ?? '1', 10),
    per_page: 5,
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
    'states[]': searchParams.get('states[]')?.split(',') ?? []
  });

  useUpdateEffect(() => {
    const benchmarkCommunities = searchParams.get('benchmark_communities[]')?.split(',') ?? [];
    const organizations = searchParams.get('organizations[]')?.split(',') ?? [];
    const specialties = searchParams.get('specialties[]')?.split(',') ?? [];
    const states = searchParams.get('states[]')?.split(',') ?? [];
    const orderByProvidersColumn = searchParams.get('order_by_providers[column]') as
      | 'full_name'
      | 'organization_name';
    const orderByProvidersDir =
      searchParams.get('order_by_providers[dir]') === 'asc' ? 'asc' : 'desc';
    const pageProviders = parseInt(searchParams.get('page_providers') ?? '1', 10);
    const orderByLocationsColumn = searchParams.get('order_by_locations[column]') as
      | 'provider_count'
      | 'state';
    const orderByLocationsDir =
      searchParams.get('order_by_locations[dir]') === 'desc' ? 'desc' : 'asc';
    const pageLocations = parseInt(searchParams.get('page_locations') ?? '1', 10);
    const orderByOrganizationsColumn = searchParams.get('order_by_organizations[column]') as
      | 'bipoc_inclusivity'
      | 'lgbq_inclusivity'
      | 'tgnc_inclusivity'
      | 'total_providers';
    const orderByOrganizationsDir =
      searchParams.get('order_by_organizations[dir]') === 'desc' ? 'desc' : 'asc';
    const pageOrganizations = parseInt(searchParams.get('page_organizations') ?? '1', 10);

    updateNetworkOverviewFilters({
      'benchmark_communities[]': benchmarkCommunities,
      'organizations[]': organizations,
      'specialties[]': specialties,
      'states[]': states
    });

    updateNetworkProviderFilters({
      'benchmark_communities[]': benchmarkCommunities,
      order_by: {
        column: orderByProvidersColumn,
        dir: orderByProvidersDir
      },
      'organizations[]': organizations,
      page: pageProviders,
      'specialties[]': specialties,
      'states[]': states
    });

    updateNetworkLocationFilters({
      'benchmark_communities[]': benchmarkCommunities,
      order_by: {
        column: orderByLocationsColumn,
        dir: orderByLocationsDir
      },
      'organizations[]': organizations,
      page: pageLocations,
      'specialties[]': specialties,
      'states[]': states
    });

    updateNetworkOrgMembersFilters({
      'benchmark_communities[]': benchmarkCommunities,
      order_by: {
        column: orderByOrganizationsColumn,
        dir: orderByOrganizationsDir
      },
      'organizations[]': organizations,
      page: pageOrganizations,
      per_page: 5,
      'specialties[]': specialties,
      'states[]': states
    });
  }, [searchParams]);

  useUpdateEffect(() => {
    setCurrentPageProviders(Number(searchParams.get('page_providers') ?? '1'));
    setSortDescriptorProviders({
      column: searchParams.get('order_by_providers[column]') ?? 'full_name',
      direction:
        searchParams.get('order_by_providers[dir]') === null
          ? 'ascending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by_providers[dir]')!
            ] as SortDirection)
    });
    setCurrentPageLocations(Number(searchParams.get('page_locations') ?? '1'));
    setSortDescriptorLocations({
      column: searchParams.get('order_by_locations[column]') ?? 'provider_count',
      direction:
        searchParams.get('order_by_locations[dir]') === null
          ? 'descending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by_locations[dir]')!
            ] as SortDirection)
    });
    setCurrentPageOrganizations(Number(searchParams.get('page_organizations') ?? '1'));
    setSortDescriptorOrganizations({
      column: searchParams.get('order_by_organizations[column]') ?? 'total_providers',
      direction:
        searchParams.get('order_by_organizations[dir]') === null
          ? 'descending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by_organizations[dir]')!
            ] as SortDirection)
    });
    setSelectedStates(new Set(searchParams.get('states[]')?.split(',') ?? []));
    setSelectedCommunitiesFilter(searchParams.get('benchmark_communities[]')?.split(',') ?? []);
    setSelectedOrganizationsFilter(searchParams.get('organizations[]')?.split(',') ?? []);
    setSelectedSpecialtiesFilter(searchParams.get('specialties[]')?.split(',') ?? []);
  }, [searchParams]);

  const selectedStatesFirst = (a: (typeof STATES)[0], b: (typeof STATES)[0]) => {
    if (
      Array.from(selectedStates).includes(a.abbreviation) &&
      !Array.from(selectedStates).includes(b.abbreviation)
    ) {
      return -1;
    } else if (
      Array.from(selectedStates).includes(b.abbreviation) &&
      !Array.from(selectedStates).includes(a.abbreviation)
    ) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <PageTitle
        description="Violet aggregates individual provider Benchmarks to deliver a comprehensive assessment of inclusivity for entire provider organizations. Use the below fields to search and filter for specific care delivery organizations or health systems in your network."
        title="Inclusivity"
        titleVariant="h1"
      />
      <Filters>
        <MultiComboBox
          aria-label="Search state(s)"
          data-cy="states-filter"
          filter={contains}
          icon={SearchIcon}
          inputValue={stateSearchValue}
          onInputChange={handleStateSearchChange}
          onSelectionChange={handleSelectedStatesChange}
          placeholder={
            Array.from(selectedStates).length > 0 ? Array.from(selectedStates).join(', ') : 'State'
          }
          selectedKeys={selectedStates}
        >
          {[
            <Item
              key="all"
              textValue="all"
            >
              <S.AllOption>All states</S.AllOption>
            </Item>
          ].concat(
            STATES.sort(selectedStatesFirst).map(state => (
              <Item key={state.abbreviation}>{state.name}</Item>
            ))
          )}
        </MultiComboBox>
        <NetworkOrganizationFilter
          aria-label="Search by provider organization"
          data-cy="organizations-filter"
          handleSelectionChange={handleOrganizationsFilterChange as (value: Key | null) => void}
          placeholder="Provider organization"
          selectedKey={selectedOrganizationsFilter[0] as Key}
        />
        <S.WideComboBox
          aria-label="Search by specialty"
          data-cy="specialties-filter"
          filter={contains}
          icon={SearchIcon}
          onSelectionChange={handleSpecialtiesFilterChange}
          placeholder="Specialty"
          selectedKey={selectedSpecialtiesFilter[0]}
        >
          {NPI_SPECIALTIES.map(specialty => (
            <Item key={specialty}>{specialty}</Item>
          ))}
        </S.WideComboBox>
        <CommunityFilter filterLabelText="Community Benchmark">
          <CheckboxGroup
            aria-labelledby="filter-label"
            direction="horizontal"
            onChange={handleFilterByCommunityChange}
            value={selectedCommunitiesFilter}
          >
            <GroupCheckbox
              data-cy="bipoc-filter"
              value="bipoc"
            >
              BIPOC
            </GroupCheckbox>
            <GroupCheckbox
              data-cy="lgbq-filter"
              value="lgbq"
            >
              LGBQ
            </GroupCheckbox>
            <GroupCheckbox
              data-cy="tgnc-filter"
              value="tgnc"
            >
              TGNC
            </GroupCheckbox>
          </CheckboxGroup>
        </CommunityFilter>
      </Filters>

      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <NetworkOverviewSection networkOverview={networkOverview} />
      )}

      <S.TableWrapper>
        <S.ExpandCollapseButton
          $isExpanded={showServiceArea}
          onPress={() => setShowServiceArea(!showServiceArea)}
        >
          {showServiceArea ? <ShowLessIcon /> : <ShowMoreIcon />}
          <S.ExpandCollapseTextWrapper $isExpanded={showServiceArea}>
            <S.ExpandCollapseTitle>Service areas</S.ExpandCollapseTitle>
            {showServiceArea && (
              <S.ExpandCollapseDescription>
                This table displays the density of inclusive providers by geographic service area.
              </S.ExpandCollapseDescription>
            )}
          </S.ExpandCollapseTextWrapper>
        </S.ExpandCollapseButton>
        {showServiceArea &&
          (isFetchingLocation ? (
            <Spinner withWrapper />
          ) : networkLocations && networkLocations.length > 0 ? (
            <ServiceAreasTable
              currentPageLocations={currentPageLocations}
              handleLocationsTableSortChange={handleLocationsTableSortChange}
              handleSetPageLocations={handleSetPageLocations}
              networkLocations={networkLocations}
              networkLocationsTotalPages={networkLocationsTotalPages}
              sortDescriptorLocations={sortDescriptorLocations}
            />
          ) : (
            <S.EmptyState>No service areas found.</S.EmptyState>
          ))}
      </S.TableWrapper>

      <S.TableWrapper>
        <S.ExpandCollapseButton
          $isExpanded={showProviderOrganizations}
          onPress={() => setShowProviderOrganizations(!showProviderOrganizations)}
        >
          {showProviderOrganizations ? <ShowLessIcon /> : <ShowMoreIcon />}
          <S.ExpandCollapseTextWrapper $isExpanded={showProviderOrganizations}>
            <S.ExpandCollapseTitle>Provider organizations</S.ExpandCollapseTitle>
            {showProviderOrganizations && (
              <S.ExpandCollapseDescription>
                This table displays provider organizations within your network.
              </S.ExpandCollapseDescription>
            )}
          </S.ExpandCollapseTextWrapper>
        </S.ExpandCollapseButton>
        {showProviderOrganizations &&
          (isFetchingProviderOrganizations ? (
            <Spinner withWrapper />
          ) : networkOrganizations && networkOrganizations.length > 0 ? (
            <NetworkMembersTable
              currentPageOrganizations={currentPageOrganizations}
              handleOrganizationsTableSortChange={handleOrganizationsTableSortChange}
              handleSetPageOrganizations={handleSetPageOrganizations}
              networkOrgMembers={networkOrganizations}
              networkOrganizationsTotalPages={networkOrgMembersTotalPages}
              sortDescriptorOrganizations={sortDescriptorOrganizations}
            />
          ) : (
            <S.EmptyState>No provider organizations found.</S.EmptyState>
          ))}
      </S.TableWrapper>

      <S.TableWrapper>
        <S.ExpandCollapseButton
          $isExpanded={showProviders}
          onPress={() => setShowProviders(!showProviders)}
        >
          {showProviders ? <ShowLessIcon /> : <ShowMoreIcon />}
          <S.ExpandCollapseTextWrapper $isExpanded={showProviders}>
            <S.ExpandCollapseTitle>Providers</S.ExpandCollapseTitle>
            {showProviders && (
              <S.ExpandCollapseDescription>
                This table displays providers within your network and the Benchmarks they have
                achieved.
              </S.ExpandCollapseDescription>
            )}
          </S.ExpandCollapseTextWrapper>
        </S.ExpandCollapseButton>
        {showProviders &&
          (isFetchingProviders ? (
            <Spinner withWrapper />
          ) : networkProviders && networkProviders.length > 0 ? (
            <NetworkProvidersTable
              currentPageProviders={currentPageProviders}
              handleProvidersTableSortChange={handleProvidersTableSortChange}
              handleSetPageProviders={handleSetPageProviders}
              networkProviders={networkProviders}
              networkProvidersTotalPages={networkProvidersTotalPages}
              sortDescriptorProviders={sortDescriptorProviders}
            />
          ) : (
            <S.EmptyState>No providers found.</S.EmptyState>
          ))}
      </S.TableWrapper>
    </>
  );
};

export default Inclusivity;
